import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { InversedAttributeStatus } from '@app/models/quote.model';
import { rangeLimits } from '@app/modules/chat/chat.contants';
import { tableStatusOptions } from '@app/modules/quote/quote.contants';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { SelectField } from '@atomic/atm.select';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Color } from '@atomic/obj.constants';
import { QuoteStrings } from '../../quote.string';
import { AttributeChemicalCompositionProps } from './technical-analysis-attribute.component';
import { TechnicalAnalysisAttributeContext } from './technical-analysis-attribute.context';
import { chemicalCompositionColumns } from './utils/chemicalCompositionColumns';

const status = {
  key: 'status',
  label: 'Status',
  type: 'number',
  isVisible: true,
  isEditable: false,
  isDeletable: false,
};

const Option = ({ value, name }) => <option value={value}>{name}</option>;

export const ChemicalComposition = (props: AttributeChemicalCompositionProps) => {
  const {
    isEditing,
    editedStatus,
    rowsStatus,
    onRowStatusChanged,
    onRowSuggestionChanged,
    onRowMinChanged,
    onRowMaxChanged,
  } = React.useContext(TechnicalAnalysisAttributeContext);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const isRangeDerogate = props.attribute.columns.some(item => item.type === 'range');
  const strings = TechnicalAnalysisStrings[userInfo.language].attributes.tableAttribute;

  const columns = chemicalCompositionColumns(props.attribute.columns, isEditing);

  const rows = props.attribute.rows.map(row => {
    return row.map(item => {
      return {
        key: item.key,
        columnRef: item.columnRef,
        value:
          typeof item.value === 'object' && item.value !== null
            ? `Min: ${item.value?.min ? item.value.min : ''} - Max: ${item.value?.max ? item.value.max : '-'}`
            : item.value
            ? item.value
            : '-',
        colSpan: 1,
        rowSpan: 1,
      };
    });
  });

  const checkStatusOption = options => {
    if (editedStatus === 'non-analysed' || editedStatus === 'accepted') {
      return options.filter(item => item.value !== 'derogate');
    } else {
      return options;
    }
  };

  return (
    <Table collapse>
      <TR bordered>
        {columns.map(column => (
          <TD key={column?.key} color={Color.Secondary} data-testid={column.label}>
            <Body>{column.label}</Body>
          </TD>
        ))}
        {isEditing && (
          <>
            <TD key={'min'} color={Color.Secondary}>
              <Body>{strings.min}</Body>
            </TD>
            <TD key={'max'} color={Color.Secondary}>
              <Body>{strings.max}</Body>
            </TD>
          </>
        )}
      </TR>
      {rows.map((row, index) => {
        const statusValue =
          rowsStatus[index]?.status !== InversedAttributeStatus['non-status']
            ? rowsStatus[index]?.status
            : InversedAttributeStatus['non-analysed'];

        return (
          <TR key={index} bordered>
            {row.map(element => {
              const value =
                element?.columnRef === status.key
                  ? QuoteStrings[userInfo.language].constants.status.label[element.value]
                  : element?.value;
              if (
                isEditing &&
                element?.columnRef !== 'status' &&
                element?.columnRef !== 'suggestion' &&
                element?.columnRef !== 'reason'
              ) {
                return (
                  <TD key={element?.key + element.columnRef} data-testid={element.key}>
                    <Body>{value === rangeLimits.max ? '-' : value}</Body>
                  </TD>
                );
              } else if (!isEditing && element?.columnRef !== 'reason') {
                return (
                  <TD key={element?.key + element.columnRef} data-testid={element.key}>
                    <Body>{value === rangeLimits.max ? '-' : value}</Body>
                  </TD>
                );
              } else {
                return <></>;
              }
            })}
            {isEditing && (
              <>
                <TD key={status?.key + index}>
                  <SelectField value={statusValue} onValueChange={value => onRowStatusChanged(value, index)}>
                    {checkStatusOption(tableStatusOptions).map((option, optionIndex) => (
                      <Option key={optionIndex} value={option.value} name={option.name} />
                    ))}
                  </SelectField>
                </TD>
                {rowsStatus[index]?.status === InversedAttributeStatus.derogate && (
                  <>
                    {!isRangeDerogate ? (
                      <TD key={'suggestion' + index}>
                        <TextField
                          value={rowsStatus[index]?.suggestion}
                          onValueChange={value => onRowSuggestionChanged(`${value}`, index)}
                        />
                      </TD>
                    ) : (
                      <>
                        <TD key={'min' + index}>
                          <TextField
                            small
                            type='number'
                            value={rowsStatus[index]?.minSuggestion}
                            onValueChange={value => onRowMinChanged(`${value}`, index, rowsStatus[index]?.status)}
                            invalid={rowsStatus[index]?.minSuggestion > rowsStatus[index]?.maxSuggestion}
                            noArrows
                          />
                        </TD>
                        <TD key={'max' + index}>
                          <TextField
                            small
                            type='number'
                            value={rowsStatus[index]?.maxSuggestion}
                            onValueChange={value => onRowMaxChanged(`${value}`, index, rowsStatus[index]?.status)}
                            invalid={rowsStatus[index]?.minSuggestion > rowsStatus[index]?.maxSuggestion}
                            noArrows
                          />
                        </TD>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </TR>
        );
      })}
    </Table>
  );
};
