import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { Plant } from '@app/models/quote.model';

interface EditSellerProps {
  accessToken: string;
  analysisId: number;
  authorizationToken: string;
  language: string;
  plant: Plant;
}

export async function editPlantsSeller(params: EditSellerProps) {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const data = {
    review: { isRequestable: true },
  };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_PLANT
  }/${params.plant.key}`;

  const { data: dataResponse } = await axios.patch(url, data, { headers: defaultHeaders });

  return dataResponse;
}
