import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EventTypes } from '@app/models/analytics.model';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Frame } from '@atomic/atm.frame';
import { Caption } from '@atomic/atm.typography';
import { FlexColumn, Separator } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { Feedback, FeedbackAnalytics, FeedbackType } from '@atomic/obj.feedback';
import { OrderInputStrings } from '../../order-input.string';
import { RESET_ORDER_INPUT_ACTION } from '../../store/order-input.reducers';
import {
  OrderInputSuccessButtonsWrapper,
  OrderInputSuccessGrid,
  OrderInputSuccessWrapper,
  SuccessMessage,
} from './order-input-success.style';

const strings = OrderInputStrings.pt.success;

export const OrderInputSuccess: React.FC = () => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setOpenFeedback(true);
    }, 500);
  }, []);

  return (
    <OrderInputSuccessWrapper data-testid='order-input-success-phase'>
      <OrderInputSuccessGrid fluid>
        <Frame>
          <FlexColumn fullWidth pt={Spacing.XXLarge} pb={Spacing.XXLarge} mr hAlign='center'>
            <FaIcon.Time size='5x' color={Color.Gray} />
            <SuccessMessage>
              {strings.successMessage} <br /> {strings.successMessage2}
            </SuccessMessage>
            <Caption medium textAlign='center'>
              {strings.secondaryMessageLine1} <br />
              {strings.secondaryMessageLine2} <br />
              {strings.secondaryMessageLine3} <b>{strings.secondaryMessageLine4}</b>
            </Caption>
            <OrderInputSuccessButtonsWrapper hAlign='center' mt>
              <Separator small />
              <Button expanded onClick={() => dispatch(RESET_ORDER_INPUT_ACTION)} kind='secondary'>
                {strings.newOrderButton}
              </Button>
            </OrderInputSuccessButtonsWrapper>
          </FlexColumn>
        </Frame>
      </OrderInputSuccessGrid>
      <Feedback
        opened={openFeedback}
        setOpened={setOpenFeedback}
        type={FeedbackType.Numeric}
        context={FeedbackAnalytics.OrderInput}
        message={strings.feedbackMessage}
        analyticsType={EventTypes.Feedback}
      />
    </OrderInputSuccessWrapper>
  );
};
