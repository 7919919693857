import { FrontierSalesOrderItem, InternationalSalesOrderItemSummary, ShippedSalesOrderItem } from '@app/models';
import { parseOrderDate } from './order-date.mapper';

export interface InternationalSalesOrderSummaryDto {
  salesOrdersOnlineSumary: InternationalSalesOrderItemSummaryDto[];
}

export interface FrontierSalesOrderDto {
  bordering: FrontierOrderItemDto[];
}

export interface ShippedSalesOrderDto {
  shipped: ShippedOrderItemDto[];
}

export interface InternationalSalesOrderItemSummaryDto {
  concatenatedSalesDocument: string;
  salesDocument: string;
  salesDocumentItem: string;
  material: string;
  materialName: string;
  plant: string;
  plantName: string;
  corporateGroup: string;
  soldToPartyCG: string;
  soldToPartyCGName: string;
  shipToPartyName: string;
  customerPurchaseOrderNumber: string;
  customerMaterialCode: string;
  dtPurchase: string;
  transportMode: string;
  shipment: string;
  dtCustomerRequired: string;
  dtEstimateStock: string;
  dtEstimateBorder: string;
  dtCustomerConfirmed: string;
  salesOrderStatus: string;
  size: string;
  sizeIn: string;
  width: string;
  thickness: string;
  minMaxLenght: string;
  multipleLength: string;
  standardGrade: string;
  externalGrade: string;
  product: string;
  shape: string;
  brand: string;
  surfaceFinishin: string;
  heatTreatment: string;
  qtyCustomerRequiredTons: number;
  qtyAgreedTons: number;
  qtyPortoFronteira: number;
  qtyShipped: number;
  qtyStockPend: number;
  qtyTotalStock: number;
  qtyWithCRT: number;
  qtyWithoutCRT: number;
  heat: string;
}

export interface FrontierOrderItemDto {
  concatenatedSalesDocument: string;
  salesDocument: string;
  salesDocumentItem: string;
  customerPurchaseOrderNumber: string;
  customerPurchaseOrderItem: string;
  plant: string;
  plantName: string;
  soldToPartyCG: string;
  soldToPartyNameCG: string;
  corporateGroup: string;
  shipToPartyName: string;
  customerMaterialCode: string;
  heat: string;
  material: string;
  materialName: string;
  minMaxLenght: string;
  multipleLength: string;
  product: string;
  shape: string;
  surfaceFinishin: string;
  heatTreatment: string;
  externalGrade: string;
  size: string;
  sizeIn: string;
  thickness: string;
  width: string;
  standardGrade: string;
  brand: string;
  dtPurchase: string;
  approvementDate: string;
  dtCustomerRequired: string;
  dtCustomerConfirmed: string;
  nfNumber: string;
  nfDate: string;
  nfQuantity: string;
  transportMode: string;
  shipment: string;
  shipmentStatus: string;
  billingDocumentDate: string;
  deliveryDocument: string;
}

export interface ShippedOrderItemDto {
  concatenatedSalesDocument: string;
  salesDocument: string;
  salesDocumentItem: string;
  customerPurchaseOrderNumber: string;
  customerPurchaseOrderItem: string;
  plant: string;
  plantName: string;
  soldToPartyCG: string;
  soldToPartyNameCG: string;
  corporateGroup: string;
  shipToPartyName: string;
  customerMaterialCode: string;
  heat: string;
  material: string;
  materialName: string;
  minMaxLenght: string;
  multipleLength: string;
  product: string;
  shape: string;
  surfaceFinishin: string;
  heatTreatment: string;
  externalGrade: string;
  size: string;
  sizeIn: string;
  thickness: string;
  width: string;
  standardGrade: string;
  brand: string;
  dtPurchase: string;
  approvementDate: string;
  dtCustomerRequired: string;
  dtCustomerConfirmed: string;
  nfNumber: string;
  nfDate: string;
  nfQuantity: string;
  transportMode: string;
  shipment: string;
  shipmentStatus: string;
  billingDocumentDate: string;
  deliveryDocument: string;
  deliveryDocumentItem: string;
}

export const mapInternationalSalesOrderSummary = (
  order: InternationalSalesOrderSummaryDto,
): InternationalSalesOrderItemSummary[] => {
  return mapInternationalSalesOrderItemSummary(order.salesOrdersOnlineSumary);
};

export const mapShippedOrder = (order: ShippedSalesOrderDto): ShippedSalesOrderItem[] => {
  return mapShippedOrderItem(order.shipped);
};

export const mapFrontierOrder = (order: FrontierSalesOrderDto): FrontierSalesOrderItem[] => {
  return mapFrontierOrderItem(order.bordering);
};

const fromToStatusMap = (salesOrderStatus): string => {
  if (salesOrderStatus === 'Implantado') {
    return 'Não Confirmado';
  } else if (salesOrderStatus === 'Em Aprazamento') {
    return 'Não confirmado - em análise';
  } else {
    return salesOrderStatus;
  }
};

const mapInternationalSalesOrderItemSummary = (
  orders: InternationalSalesOrderItemSummaryDto[],
): InternationalSalesOrderItemSummary[] => {
  return orders.map(order => {
    return {
      concatenatedSalesDocument: order.concatenatedSalesDocument,
      salesDocument: order.salesDocument,
      salesDocumentItem: order.salesDocumentItem,
      material: order.material,
      materialName: order.materialName,
      plant: order.plant,
      plantName: order.plantName,
      corporateGroup: order.corporateGroup,
      soldToPartyCG: order.soldToPartyCG,
      soldToPartyCGName: order.soldToPartyCGName,
      shipToPartyName: order.shipToPartyName,
      customerPurchaseOrderNumber: order.customerPurchaseOrderNumber,
      customerMaterialCode: order.customerMaterialCode,
      dtPurchase: parseOrderDate(order.dtPurchase),
      transportMode: order.transportMode,
      shipment: order.shipment,
      dtCustomerRequired: parseOrderDate(order.dtCustomerRequired),
      dtEstimateStock: parseOrderDate(order.dtEstimateStock),
      dtEstimateBorder: parseOrderDate(order.dtEstimateBorder),
      dtCustomerConfirmed: parseOrderDate(order.dtCustomerConfirmed),
      salesOrderStatus: fromToStatusMap(order.salesOrderStatus),
      size: order.size,
      sizeIn: order.sizeIn,
      width: order.width,
      thickness: order.thickness,
      minMaxLength: order.minMaxLenght,
      multipleLength: order.multipleLength,
      standardGrade: order.standardGrade,
      externalGrade: order.externalGrade,
      product: order.product,
      shape: order.shape,
      brand: order.brand,
      surfaceFinishing: order.surfaceFinishin,
      heatTreatment: order.heatTreatment,
      qtyCustomerRequiredTons: order.qtyCustomerRequiredTons,
      qtyAgreedTons: order.qtyAgreedTons,
      qtyInFrontier: order.qtyPortoFronteira,
      qtyShipped: order.qtyShipped,
      qtyStockPend: order.qtyStockPend,
      qtyTotalStock: order.qtyTotalStock,
      qtyWithCRT: order.qtyWithCRT,
      qtyWithoutCRT: order.qtyWithoutCRT,
      heat: order.heat,
    };
  });
};

const mapShippedOrderItem = (shippedOrder: ShippedOrderItemDto[]): ShippedSalesOrderItem[] => {
  return shippedOrder.map(item => {
    return {
      concatenatedSalesDocument: item.concatenatedSalesDocument,
      salesDocument: item.salesDocument,
      salesDocumentItem: item.salesDocumentItem,
      customerPurchaseOrderNumber: item.customerPurchaseOrderNumber,
      customerPurchaseOrderItem: item.customerPurchaseOrderItem,
      plant: item.plant,
      plantName: item.plantName,
      soldToPartyCG: item.soldToPartyCG,
      soldToPartyNameCG: item.soldToPartyNameCG,
      corporateGroup: item.corporateGroup,
      shipToPartyName: item.shipToPartyName,
      customerMaterialCode: item.customerMaterialCode,
      heat: item.heat,
      material: item.material,
      materialName: item.materialName,
      minMaxLength: item.minMaxLenght,
      multipleLength: item.multipleLength,
      product: item.product,
      shape: item.shape,
      surfaceFinishing: item.surfaceFinishin,
      heatTreatment: item.heatTreatment,
      externalGrade: item.externalGrade,
      size: item.size,
      sizeIn: item.sizeIn,
      thickness: item.thickness,
      width: item.width,
      standardGrade: item.standardGrade,
      brand: item.brand,
      dtPurchase: parseOrderDate(item.dtPurchase),
      approvementDate: parseOrderDate(item.approvementDate),
      dtCustomerRequired: parseOrderDate(item.dtCustomerRequired),
      dtCustomerConfirmed: parseOrderDate(item.dtCustomerConfirmed),
      nfNumber: item.nfNumber,
      nfDate: parseOrderDate(item.nfDate),
      nfQuantity: item.nfQuantity,
      transportMode: item.transportMode,
      shipment: item.shipment,
      shipmentStatus: item.shipmentStatus,
      billingDocumentDate: parseOrderDate(item.billingDocumentDate),
      deliveryDocument: item.deliveryDocument,
      deliveryDocumentItem: item.deliveryDocumentItem,
    };
  });
};

const mapFrontierOrderItem = (shippedOrder: FrontierOrderItemDto[]): FrontierSalesOrderItem[] => {
  return shippedOrder.map(item => {
    return {
      concatenatedSalesDocument: item.concatenatedSalesDocument,
      salesDocument: item.salesDocument,
      salesDocumentItem: item.salesDocumentItem,
      customerPurchaseOrderNumber: item.customerPurchaseOrderNumber,
      customerPurchaseOrderItem: item.customerPurchaseOrderItem,
      plant: item.plant,
      plantName: item.plantName,
      soldToPartyCG: item.soldToPartyCG,
      soldToPartyNameCG: item.soldToPartyNameCG,
      corporateGroup: item.corporateGroup,
      shipToPartyName: item.shipToPartyName,
      customerMaterialCode: item.customerMaterialCode,
      heat: item.heat,
      material: item.material,
      materialName: item.materialName,
      minMaxLength: item.minMaxLenght,
      multipleLength: item.multipleLength,
      product: item.product,
      shape: item.shape,
      surfaceFinishing: item.surfaceFinishin,
      heatTreatment: item.heatTreatment,
      externalGrade: item.externalGrade,
      size: item.size,
      sizeIn: item.sizeIn,
      thickness: item.thickness,
      width: item.width,
      standardGrade: item.standardGrade,
      brand: item.brand,
      dtPurchase: parseOrderDate(item.dtPurchase),
      approvementDate: parseOrderDate(item.approvementDate),
      dtCustomerRequired: parseOrderDate(item.dtCustomerRequired),
      dtCustomerConfirmed: parseOrderDate(item.dtCustomerConfirmed),
      nfNumber: item.nfNumber,
      nfDate: parseOrderDate(item.nfDate),
      nfQuantity: item.nfQuantity,
      transportMode: item.transportMode,
      shipment: item.shipment,
      shipmentStatus: item.shipmentStatus,
      billingDocumentDate: parseOrderDate(item.billingDocumentDate),
      deliveryDocument: item.deliveryDocument,
    };
  });
};
