import { LayoutTabs } from '@app/models/layout.model';

interface IOrderFilterKeys {
  ov: string;
  material: string;
  descMaterial: string;
  codMaterialClient: string;
  dateSolClient: string;
  dateConfirmed: string;
  plantName: string;
}

interface IOrderOpenMIFilterKeys extends IOrderFilterKeys {
  diameter: string;
  thickness: string;
  width: string;
  qtdConf: string;
  qtyStock: string;
  qtyTotalProduction: string;
  qtyDelivery: string;
  qtyTotalBilled: string;
  qtyBillingOpen: string;
  qtdSolClient: string;
}
interface IFinanceFilterKeys {
  documentNumber: string;
  invoiceKey: string;
  launchDate: string;
  experyDate: string;
  rangeDaysOverdue: string;
  documentType: string;
}

export interface ISidebarFiltersStrings {
  sideBarTitle: string;
  orderTabsTitles: {
    [LayoutTabs.OpenMI]: IOrderOpenMIFilterKeys;
    [LayoutTabs.StockMI]: IOrderFilterKeys;
    [LayoutTabs.BilledMI]: IOrderFilterKeys;
    [LayoutTabs.FinanceMI]: IFinanceFilterKeys;
  };
  financeTitles: IFinanceFilterKeys;
  clipboardInfo: string;
  rangeInfo: string;
  addMoreFilter: string;
  activeFilters: string;
  cleanAll: string;
  filterButton: string;
  from: string;
  to: string;
}

interface ISidebarFiltersLanguages {
  pt: ISidebarFiltersStrings;
  es: ISidebarFiltersStrings;
  in: ISidebarFiltersStrings;
}

const pt: ISidebarFiltersStrings = {
  sideBarTitle: 'Filtros avançados',
  orderTabsTitles: {
    [LayoutTabs.OpenMI]: {
      ov: 'OVs',
      material: 'Materiais',
      descMaterial: 'Desc. materiais',
      codMaterialClient: 'Part. number',
      dateSolClient: 'Data solicitada cliente',
      dateConfirmed: 'Data confirmada no estoque',
      plantName: 'Usina',
      diameter: 'Bitola (mm)',
      thickness: 'Espesura',
      width: 'Largura',
      qtdConf: 'Qtd. confirmada (t)',
      qtyStock: 'Qtd. em estoque (t)',
      qtyTotalProduction: 'Qtd. em produção (t)',
      qtyDelivery: 'Qtd. em remessa (t)',
      qtyTotalBilled: 'Qtd. faturada (t)',
      qtyBillingOpen: 'Qtd. pend. fat. (t)',
      qtdSolClient: 'Qtd. solicitada (t)',
    },
    [LayoutTabs.StockMI]: {
      ov: 'OVs',
      material: 'Materiais',
      descMaterial: 'Desc. materiais',
      codMaterialClient: 'Part. number',
      dateSolClient: 'Data solicitada cliente',
      dateConfirmed: 'Data confirmada',
      plantName: 'Usina',
    },
    [LayoutTabs.BilledMI]: {
      ov: 'OVs',
      material: 'Materiais',
      descMaterial: 'Desc. materiais',
      codMaterialClient: 'Part. number',
      dateSolClient: 'Data solicitada cliente',
      dateConfirmed: 'Data faturamento',
      plantName: 'Usina',
    },
    [LayoutTabs.FinanceMI]: {
      documentNumber: 'NºNF/ND',
      invoiceKey: 'Chave de acesso',
      launchDate: 'Data documento',
      experyDate: 'Vencimento',
      rangeDaysOverdue: 'Status vencimento',
      documentType: 'Tipo de documento',
    },
  },
  financeTitles: {
    documentNumber: 'NºNF/ND',
    invoiceKey: 'Chave de acesso',
    launchDate: 'Data documento',
    experyDate: 'Vencimento',
    rangeDaysOverdue: 'Status vencimento',
    documentType: 'Tipo de documento',
  },
  clipboardInfo:
    'Caso queira colar os dados de outra tabela, ao colar no primeiro campo os outros preenchem automaticamente. (Máximo de 200 registros)',
  rangeInfo: 'Ajuste a régua de valores para o intervalo que deseja filtrar.',
  addMoreFilter: 'Adicionar mais campos',
  activeFilters: 'Filtros aplicados:',
  cleanAll: 'Limpar tudo',
  filterButton: 'Filtrar',
  from: 'de:',
  to: 'até:',
};

export const Strings: ISidebarFiltersLanguages = {
  pt,
  es: pt,
  in: pt,
};
