import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { OrderInputImplantationTypeEnum } from '@app/modules/order-input/order-input.constants';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { H3 } from '@atomic/atm.typography';

const strings = OrderInputStrings.pt.selectItens;

export const OrderInputAmountTitle: React.FC = () => {
  const { implantationType } = useSelector((state: RootState) => state.orderInput);

  let title = '';

  switch (implantationType) {
    case OrderInputImplantationTypeEnum.ItemSelection:
      title = strings.title;
      break;
    case OrderInputImplantationTypeEnum.UploadSpreadsheet:
      title = strings.uploadOptionTitle;
      break;
    case OrderInputImplantationTypeEnum.GptRead:
      title = strings.uploadIAReadOptionTitle;
      break;
    default:
      title = strings.title;
      break;
  }

  return <H3>{title}</H3>;
};
