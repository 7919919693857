import { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { SelectOption } from '@app/modules/order-input/order-input.interfaces';
import { updateArray } from '@app/utils/array-helper';
import { SLICE_NAME } from '../shipping-request.constants';
import {
  IDeleteItem,
  IOrderTabOpenState,
  IRemovedAlertPayload,
  ISetTableRowsPayload,
  IUpdateEatonTableRequestedShippingQuantity,
  IUpdateObservationPayload,
  IUpdateRequestedEatonShippingQuantity,
  IUpdateRequestedShippingQuantity,
} from '../shipping-request.interfaces';
import { getFilteredPlantGroup, mapShippingRequestTableRows } from '../shipping-request.utils';
import { initialState } from './shipping-request.initial';

const RESET_SHIPPING_REQUEST_ACTION_TYPE = `${SLICE_NAME}/reset`;
export const RESET_SHIPPING_REQUEST_ACTION = { type: RESET_SHIPPING_REQUEST_ACTION_TYPE };

export const reducers = {
  setShowShippingRequest: (state: IOrderTabOpenState, { payload }) => {
    state.showShippingRequest = payload;
  },
  setOpenShippingFeedback: (state: IOrderTabOpenState, { payload }) => {
    state.openShippingFeedback = payload;
  },
  setTableRows: (state: IOrderTabOpenState, { payload }: PayloadAction<ISetTableRowsPayload>) => {
    const { selectedRows, isGKN, isEaton } = payload;
    const mappedShippingRequestTableRows = mapShippingRequestTableRows(selectedRows, isGKN, state.tableRows);

    if (isEaton && mappedShippingRequestTableRows) {
      mappedShippingRequestTableRows.forEach(row => {
        const suggestionCurrentRow = state.suggestionOptions?.find(
          option => option.item?.salesOrder === row.salesOrder,
        );

        if (suggestionCurrentRow) {
          row.requestedShippingQuantity = suggestionCurrentRow.item.requestedShippingQuantity;
        }
      });
    }

    state.tableRows = mappedShippingRequestTableRows;
  },
  updateObservations: (state: IOrderTabOpenState, { payload }: PayloadAction<IUpdateObservationPayload>) => {
    const { salesOrder, heat, value } = payload;

    if (state.tableRows) {
      const index = state.tableRows.findIndex(row => row.salesOrder === salesOrder && row.heat === heat);
      state.tableRows[index].observations = value;
    }
  },
  updateRequestedShippingQuantity: (
    state: IOrderTabOpenState,
    { payload }: PayloadAction<IUpdateRequestedShippingQuantity>,
  ) => {
    const { salesOrder, heat, value } = payload;

    if (state.tableRows) {
      const index = state.tableRows.findIndex(row => row.salesOrder === salesOrder && row.heat === heat);
      state.tableRows[index].requestedShippingQuantity = parseFloat(value);
    }
  },
  updateEatonTableRequestedShippingQuantity: (
    state: IOrderTabOpenState,
    { payload }: PayloadAction<IUpdateEatonTableRequestedShippingQuantity>,
  ) => {
    const { salesOrder, value, material } = payload;

    if (state.tableRows) {
      const index = state.tableRows.findIndex(row => row.salesOrder === salesOrder && row.material === material);
      state.tableRows[index].requestedShippingQuantity = parseFloat(value);
    }
  },
  updateEatonSuggestionRequestedShippingQuantity: (
    state: IOrderTabOpenState,
    { payload }: PayloadAction<IUpdateRequestedEatonShippingQuantity>,
  ) => {
    const { salesOrder, value, hasQtyError } = payload;
    if (!isNaN(value as any) && !isNaN(parseFloat(value))) {
      if (state.suggestionOptions) {
        const index = state.suggestionOptions.findIndex(row => row.item.salesOrder === salesOrder);

        state.suggestionOptions[index].item.requestedShippingQuantity = parseFloat(value);
        state.suggestionOptions[index].hasQtyError = hasQtyError;
      }
    } else if (value !== undefined) {
      if (state.suggestionOptions) {
        const index = state.suggestionOptions.findIndex(row => row.item.salesOrder === salesOrder);

        if (state.suggestionOptions[index]) {
          state.suggestionOptions[index].item.requestedShippingQuantity = null;
          state.suggestionOptions[index].hasQtyError = hasQtyError;
        }
      }
    }
  },
  setOpenReceiverModalIndex: (state: IOrderTabOpenState, { payload }: PayloadAction<number>) => {
    state.openReceiverModalIndex = payload;
  },
  setOpenAlertQtyModal: (state: IOrderTabOpenState, { payload }: PayloadAction<boolean>) => {
    state.openAlertQtyModal = payload;
  },
  updateReceiver: (state: IOrderTabOpenState, { payload }: PayloadAction<string>) => {
    const newReceiver = state.receiverOptions.find(opt => opt.value === payload);
    const newReceiverSelected = { tableRowIndex: state.openReceiverModalIndex, name: newReceiver.name };
    const updatedSelectedNewReceivers = updateArray(
      newReceiverSelected.tableRowIndex,
      'tableRowIndex',
      state.selectedNewReceivers,
      newReceiverSelected,
    );

    state.selectedNewReceivers = updatedSelectedNewReceivers;
    state.tableRows[state.openReceiverModalIndex].newReceiver = payload;
    state.openReceiverModalIndex = null;
  },
  setReceiverOptions: (state: IOrderTabOpenState, { payload }: PayloadAction<SelectOption[]>) => {
    state.receiverOptions = payload;
  },
  deleteItem: (state: IOrderTabOpenState, { payload }: PayloadAction<IDeleteItem>) => {
    const { salesOrder, heat, material } = payload;
    const tableRows = state.tableRows;
    const filteredTableRows = tableRows.filter(row => row.salesOrder !== salesOrder || row.heat !== heat);
    const index = state.tableRows.findIndex(
      row => row.salesOrder === salesOrder && row.heat === heat && row.material === material,
    );
    const plantName = state.tableRows[index].plantName.toLocaleLowerCase();
    const materialBP = state.tableRows[index].customerId;
    const materialItem = state.tableRows[index].material;
    const plantGroup = state[plantName];
    const filteredMaterialGroup = getFilteredPlantGroup(plantGroup, materialBP, materialItem, heat);
    state[plantName] = filteredMaterialGroup;
    state.tableRows = filteredTableRows;
  },
  removeAlert: (state: IOrderTabOpenState, { payload }: PayloadAction<IRemovedAlertPayload>) => {
    const { salesOrder, heat, value } = payload;

    if (state.tableRows) {
      const index = state.tableRows.findIndex(row => row.salesOrder === salesOrder && row.heat === heat);
      const plantName = state.tableRows[index].plantName.toLocaleLowerCase();
      const materialBP = state.tableRows[index].customerId;
      const materialItem = state.tableRows[index].material;
      state.tableRows[index].removedAlert = value;

      const materialGroup = state[plantName]?.bpGroup
        .find(group => group.bp === materialBP)
        ?.orderedShippingMaterialUserGroup?.find(
          shippingMaterialGroup => shippingMaterialGroup.material === materialItem,
        );

      if (materialGroup) {
        const currentOV = materialGroup.ovs.find(ov => ov.salesOrder === salesOrder);
        currentOV.removedAlert = value;
      }
    }
  },
  setOpenShippingRequestSuggestionModalMaterial: (state: IOrderTabOpenState, { payload }) => {
    state.openShippingRequestSuggestionModalMaterial = payload;
    state.suggestionOptions = [];
  },
  setMogPlant: (state: IOrderTabOpenState, { payload }) => {
    state.mog = payload;
  },
  setPinPlant: (state: IOrderTabOpenState, { payload }) => {
    state.pin = payload;
  },
  setChaPlant: (state: IOrderTabOpenState, { payload }) => {
    state.cha = payload;
  },
  setSuggestions: (state: IOrderTabOpenState, { payload }) => {
    state.suggestionOptions = payload;
  },
  updateSuggestion: (state: IOrderTabOpenState, { payload }) => {
    const { salesOrder, hasError, value } = payload;

    if (state.suggestionOptions) {
      const index = state.suggestionOptions.findIndex(row => row.item.salesOrder === salesOrder);
      state.suggestionOptions[index].item.requestedShippingQuantity = value;
      state.suggestionOptions[index].hasQtyError = hasError;
    }
  },
  setSuggestionHistory: (state: IOrderTabOpenState, { payload }) => {
    state.suggestionOptionHistory = payload;
  },
  reset: () => {
    return initialState;
  },
};
