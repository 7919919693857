import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { getGridLayout } from '@app/core/redux/grid-layout/thunks/grid-layout-get.thunk';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { useCreditQuery } from '@app/domain';
import { EventTypes } from '@app/models/analytics.model';
import { MarketType } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { Frame } from '@atomic/atm.frame';
import { useBooleanState } from '@atomic/obj.boolean-state/boolean-state.hook';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { FontSize, SHOW_FEEDBACK_DELAY } from '@atomic/obj.constants';
import { Feedback, FeedbackAnalytics, FeedbackType } from '@atomic/obj.feedback';
import { Col, Grid, VSeparator } from '@atomic/obj.grid';
import { SidebarFilters } from '../components/side-bar-filters/sidebar-filters.component';
import { ZeroState } from '../components/zero-state/zero-state.component';
import { FinanceCredit } from './credit/finance-credit.component';
import { FinanceGrid } from './finance-grid.component';
import { Strings, IFinanceStrings } from './finance.string';
import { FraudAlertModal } from './fraud-alert-modal/fraud-alert-modal.component';
import { FinanceLimitModal } from './limit-modal/finance-limit-modal.component';

const AnalyticsContext = {
  [MarketType.Internal]: FeedbackAnalytics.FinanceMI,
  [MarketType.External]: FeedbackAnalytics.FinanceME,
};

export const FinancePage: React.FC = () => {
  const { currentMarket, clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const customerIds = clientBpsSelected.map(client => client.customerId).join(',');
  const { tabs } = useSelector((state: RootState) => state.gridLayout);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppThunkDispatch>();

  const strings: IFinanceStrings = Strings[userInfo?.language];

  const [openFeedback, setOpenFeedback] = React.useState(false);
  const { value: modalOpen, setValue: setModalOpen } = useBooleanState(false);

  const creditQuery = useCreditQuery();

  const handleModalOpen = (open: boolean) => () => {
    LogAnalytics.pageView('FinancasLimite');
    setModalOpen(open);
  };
  useEffect(() => {
    if (userInfo?._id) {
      dispatch(getGridLayout());
    }
  }, [clientBpsSelected]);

  useEffect(() => {
    if (clientBpsSelected.length) {
      creditQuery.fetch(customerIds, clientBpsSelected[0].market);
    }
  }, [clientBpsSelected]);

  useEffect(() => {
    setTimeout(() => setOpenFeedback(true), SHOW_FEEDBACK_DELAY);
  }, []);

  if (!clientBpsSelected.length) {
    return <ZeroState />;
  }

  if (!tabs?.length || !userInfo?._id) {
    return null;
  }

  const canShowLimit = () => {
    let canShow = true;

    if (
      !creditQuery.data ||
      creditQuery?.data?.length === 0 ||
      (creditQuery && creditQuery?.data[0] && !creditQuery?.data[0].limitCredit) ||
      (creditQuery && creditQuery?.data[0] && !creditQuery?.data[0].limitValidDate) ||
      (creditQuery && creditQuery?.data[0] && !creditQuery?.data[0].percentUsedCredt)
    ) {
      canShow = false;
    }

    return canShow;
  };

  return (
    <FlexRow fullWidth>
      <SidebarFilters />
      <FlexColumn>
        {currentMarket === MarketType.Internal && canShowLimit() && (
          <Frame>
            <VSeparator />
            <Grid fluid>
              <FlexRow>
                <Col xs={12}>
                  <FlexRow>
                    <FlexColumn vAlign='center'>
                      <FinanceCredit
                        data={creditQuery.data}
                        loading={!!creditQuery.loading}
                        error={!!creditQuery.error}
                      />
                    </FlexColumn>
                    <FlexColumn noGrow pb={FontSize.XSmall}>
                      <Button kind='primary' disabled={!creditQuery.data?.length} onClick={handleModalOpen(true)}>
                        {strings?.showLimits}
                      </Button>
                    </FlexColumn>
                  </FlexRow>
                </Col>
              </FlexRow>
            </Grid>
          </Frame>
        )}
        <FraudAlertModal />
        <FinanceLimitModal opened={modalOpen} onClose={handleModalOpen(false)} creditQuery={creditQuery} />
        <FinanceGrid clientNumber={customerIds} />
        <Feedback
          opened={openFeedback}
          setOpened={setOpenFeedback}
          context={AnalyticsContext[currentMarket]}
          type={FeedbackType.Numeric}
          message={strings?.feedback}
          analyticsType={EventTypes.FinanceFeedback}
        />
      </FlexColumn>
    </FlexRow>
  );
};
