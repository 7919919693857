import React from 'react';
import { useSelector } from 'react-redux';
import * as Scroll from 'react-scroll';
import { RootState } from '@app/core/redux/store';
import { AttributesTableItem } from '@app/models/chat.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { Divisor } from '@atomic/atm.divisor';
import { FaIcon } from '@atomic/atm.fa-icon';
import { SelectField } from '@atomic/atm.select';
import { TextField } from '@atomic/atm.text-field';
import { Body, ButtonText, H1 } from '@atomic/atm.typography';
import { Tab } from '@atomic/mol.tab';
import { Table, TD, TH, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { Modal } from '@atomic/obj.modal';
import { Visibility } from '@atomic/obj.visibility';
import { useGetElementsCQList } from '../../hooks/useGetElementsCQList';
import { rangeLimits } from '../form.contants';
import { FormContext } from '../form.context';
import { FormStrings } from '../form.string';
import {
  CompositionTabsWrapperStyled,
  CompositionContentWrapperStyled,
  CompositionSearchWrapperStyled,
  CompositionTabForm,
  CompositionTabList,
  CompositionWrapper,
  CompositionContainerStyled,
  CompositionItemStyled,
} from './modal-composition.component.style';

export interface CompositionContext {
  ELEMENTOS: AttributesTableItem[];
}

export interface Element {
  element: string;
  value: {
    max?: number;
    min?: number;
  };
  unit?: string;
}

interface ModalCompositionProps {
  data: Element[];
  open: boolean;
  submitModals: (stepKey: string, key: string, values: any) => void;
}

interface CompositionProps {
  element: string;
  min: number;
  max: number;
  unit: string;
  index: number;
  strings?: any;
  onRemove: (element: string) => void;
  activeElements?: string[];
  setSelectedElements?: any;
  selectedElements?: string[];
}

const MAX_VALUE_PORC = 99;
const MAX_VALUE_PPM = 99;

export const formatDecimals = (value: string) => {
  return value.toString().replace(',', '.');
};

const elementsOrder = ['C', 'Mn', 'Si', 'P', 'S', 'Cr', 'Ni', 'Mo', 'Cu'];
export const ElementSort = (data, field) => {
  const sorting = elementsOrder.reduce(
    (object, item, index) => ({
      ...object,
      [item]: index,
    }),
    {},
  );
  return data.sort((a, b) => sorting[a[field]] - sorting[b[field]]);
};

const mandatoryElementsList = ['C', 'Mn', 'Si'];

const Composition: React.FC<CompositionProps> = props => {
  const [unitEl, setUniEl] = React.useState(props.unit);
  const [min, setMin] = React.useState(props.min);
  const [max, setMax] = React.useState(props.max);

  React.useEffect(() => {
    setMin(min && min < 0 ? 0 : min);
    setMax(max && max < 0 ? 0 : max);
  }, [min, max]);

  const handleChangeUnit = e => {
    setUniEl(e);
  };

  const handleRemove = () => {
    props.onRemove(props.element);
  };

  const handleChangeMin = value => {
    setMin(value);
  };

  const handleChangeMax = value => {
    setMax(value);
  };

  const handleChangeCQ = value => {
    props.onRemove(props.element);
    props.setSelectedElements(prev => {
      return [...prev, value];
    });
  };

  const checkValue = (value, field) => {
    const valConverted: any = value && value !== '-' ? parseFloat(value.replace(',', '.')) : '';
    if (valConverted < 0 || valConverted === '-') {
      field === 'min' ? handleChangeMin(0) : handleChangeMax(0);
    } else if (valConverted >= 99) {
      field === 'min' ? handleChangeMin(0) : handleChangeMax(99);
    } else {
      field === 'min' ? handleChangeMin(valConverted) : handleChangeMax(valConverted);
    }
  };

  return (
    <TR>
      <TD>
        <Form.Field
          name={`ELEMENTOS[${props.index}].elemento`}
          initialValue={props.element}
          hideErrorCaption
          onValueChange={value => handleChangeCQ(value)}
          validators={[Validators.Required(props.strings.fields.alert)]}
        >
          {mandatoryElementsList.includes(props?.element) ? (
            <Body>{props.element}</Body>
          ) : (
            <SelectField>
              <option value=''>{props.element}</option>
              {props.activeElements !== undefined &&
                props.activeElements.map((el: any) => (
                  <option value={el.key} key={el.label}>
                    {el.label}
                  </option>
                ))}
            </SelectField>
          )}
        </Form.Field>
      </TD>
      <TD>
        <Form.Field
          initialValue={min}
          name={`ELEMENTOS[${props.index}].min`}
          onValueChange={value => handleChangeMin(value)}
          hideErrorCaption
          validators={[
            Validators.MaxValue(unitEl === 'ppm' ? MAX_VALUE_PPM : MAX_VALUE_PORC, min, props.strings.fields.alertMax),
            Validators.HasAtLeastOne([min, max], props.strings.fields.alert),
            Validators.MinMoreThanMax([min, max], props.strings.fields.alertMinMoreMax),
          ]}
        >
          <CompositionWrapper>
            <TextField
              placeholder='Min.'
              type='text'
              noArrows={true}
              onBlur={value => checkValue(value.target.value, 'min')}
            />
          </CompositionWrapper>
        </Form.Field>
      </TD>
      <TD> - </TD>
      <TD>
        <Form.Field
          initialValue={max && max.toString().replace(rangeLimits.maxString, '')}
          name={`ELEMENTOS[${props.index}].max`}
          onValueChange={value => handleChangeMax(value)}
          hideErrorCaption
          validators={[
            Validators.MaxValue(unitEl === 'ppm' ? MAX_VALUE_PPM : MAX_VALUE_PORC, max, props.strings.fields.alertMax),
            Validators.HasAtLeastOne([min, max], props.strings.fields.alert),
            //Validators.MinMoreThanMax([min, max], props.strings.fields.alertMinMoreMax),
          ]}
        >
          <CompositionWrapper>
            <TextField
              placeholder='Máx.'
              type='text'
              noArrows={true}
              onBlur={value => checkValue(value.target.value, 'max')}
            />
          </CompositionWrapper>
        </Form.Field>
      </TD>
      <TD>
        <Form.Field
          initialValue={props.unit?.toLowerCase() || '%'}
          onValueChange={e => handleChangeUnit(e)}
          name={`ELEMENTOS[${props.index}].unit`}
        >
          <SelectField>
            <option value='%'>%</option>
            <option value='ppm'>PPM</option>
          </SelectField>
        </Form.Field>
      </TD>
      <TD>
        {!mandatoryElementsList.includes(props?.element) && (
          <Button kind='link' onClick={handleRemove}>
            <FaIcon.Trash />
          </Button>
        )}
      </TD>
    </TR>
  );
};

const CompositionTab = props => {
  return (
    <Tab initialIndex={props.tabIndex} onIndexChanged={props.handleTabChange}>
      {props.labels.map((item, index) => (
        <Tab.Item key={index}>
          <Hbox>
            <Hbox.Item>{item}</Hbox.Item>
          </Hbox>
        </Tab.Item>
      ))}
    </Tab>
  );
};

const CompositionList = props => {
  const [elements, setElements] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const [searchStatus, setSearchStatus] = React.useState(false);

  const convertedElements = (data: any) => {
    if (data.length > 0) {
      return data.reduce((acc, item) => {
        acc.push(item);
        return acc;
      }, []);
    }
  };

  React.useEffect(() => {
    setElements(props.listElements);
  }, [props.listElements]);

  React.useEffect(() => {
    const results = elements
      ? elements
          .filter(
            el => el.toLowerCase() === searchTerm.toLowerCase() || el.toLowerCase().includes(searchTerm.toLowerCase()),
          )
          .sort()
      : [];

    if (results.length > 0) {
      setSearchStatus(false);
    } else {
      setSearchStatus(true);
    }

    setSearchResults(results);
  }, [elements, searchTerm, props.selectedElements, props.listElements]);

  const handleSearchElement = e => {
    setSearchTerm(e.target.value);
  };

  const normEl = props.normElements ? props.normElements.map(el => el.element) : [];
  const compareElements = (array: string[], element: string) => {
    return array.map(el => el && el.toUpperCase()).includes(element.toUpperCase());
  };

  return (
    <Visibility key={props.id} visible={props.tabIndex === props.id}>
      <CompositionSearchWrapperStyled>
        <TextField
          placeholder={`${props.strings.searchPlaceholder} ${props.strings.typeElement[props.id]}`}
          initialValue={props.searchTerm}
          onKeyUp={handleSearchElement}
          hasButton
        >
          <Button kind='link'>
            <FaIcon.Search size='1x' />
          </Button>
        </TextField>
      </CompositionSearchWrapperStyled>

      <VSeparator />
      <LoadingState loading={props.loading}>
        <Grid fluid>
          <Row>
            {searchStatus ? (
              <Body>{props.strings.searchNoResults}</Body>
            ) : (
              <CompositionContainerStyled numColumns={props.typeElements === 'simple' ? 2 : 1}>
                {searchResults.map((element, index) => (
                  <CompositionItemStyled key={index} xs={props.numCols}>
                    <CheckboxField
                      key={element}
                      id={element}
                      checked={
                        compareElements(props.selectedElements, element) ||
                        compareElements(convertedElements(props.elements), element) ||
                        compareElements(normEl, element)
                      }
                      disabled={
                        compareElements(convertedElements(props.elements), element) || compareElements(normEl, element)
                      }
                      onValueChange={(_, checked) => props.handleSelectedElements(element, checked)}
                    >
                      {element}
                    </CheckboxField>
                  </CompositionItemStyled>
                ))}
              </CompositionContainerStyled>
            )}
          </Row>
        </Grid>
      </LoadingState>
    </Visibility>
  );
};

export const ModalComposition: React.FC<ModalCompositionProps> = props => {
  const { setModalToOpen } = React.useContext(FormContext);
  const [mandatoryElements, setMandatoryElements] = React.useState(mandatoryElementsList);
  const [normElements, setNormElements] = React.useState([]);
  const [opened, setOpened] = React.useState(props.open);
  const [step, setStep] = React.useState(1);
  const [selectedElements, setSelectedElements] = React.useState([]);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const [allElements, setAllElements] = React.useState([]);

  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const strings = FormStrings[userInfo.language].modalComposition;

  const { data: elementsList, isLoading: loadingElementList } = useGetElementsCQList({
    name: 'ChemicalComposition',
    plant: 'All',
    type: 'General',
    version: 1,
    language: userInfo.language,
    authorizationToken: azureToken,
    accessToken: token,
  });

  React.useEffect(() => {
    if (elementsList) {
      setAllElements(elementsList.simpleElements.concat(elementsList.composedElements));
    }
    if (props.data) {
      setNormElements(joinElements(mandatoryElementsList, props.data));
    } else {
      setNormElements(mandatoryElementsList);
    }
  }, [props.data, elementsList]);

  const joinElements = (mandatory: string[], norm?: Element[]) => {
    const mandatoryEl = mandatory.map(el => ({ element: el, value: { max: null, min: null }, unit: null }));
    const allEl = norm.concat(mandatoryEl);
    const result = allEl.reduce((acc, obj) => {
      const hasElement = acc.find((item: Element) => item.element === obj.element);
      if (hasElement) {
        if (
          (hasElement.value.min === null || hasElement.value.max === null) &&
          obj.value.min !== null &&
          obj.value.max !== null
        ) {
          Object.assign(hasElement, obj);
        }
      } else {
        acc.push(obj);
      }
      return acc;
    }, []);

    return result;
  };

  const validateRange = (values: CompositionContext) => {
    const convertedValues = [];
    values.ELEMENTOS.forEach((element, index) => {
      convertedValues[index] = { min: parseFloat(element.min), max: parseFloat(element.max) };
    });

    return convertedValues.some(el =>
      el.min && el.max
        ? el.min > el.max || ((el.min === 0 || isNaN(el.min)) && (el.max === 0 || isNaN(el.max)))
          ? true
          : false
        : (!el.min && (el.max === 0 || isNaN(el.max))) || ((el.min === 0 || isNaN(el.min)) && !el.max)
        ? true
        : false,
    );
  };

  const handleClose = () => {
    setOpened(false);
    setModalToOpen(null);
  };

  const handleSubmit = (formData: FormData<CompositionContext>) => {
    if (Object.keys(formData.error).length) {
      if (
        Object.values(formData.error.ELEMENTOS)[0].max?.name === 'LimitValue' ||
        Object.values(formData.error.ELEMENTOS)[0].min?.name === 'LimitValue'
      ) {
        show('alert', strings.fields.alertMax);
      } else if (
        Object.values(formData.error.ELEMENTOS)[0].max?.name === 'MinMoreThanMax' ||
        Object.values(formData.error.ELEMENTOS)[0].min?.name === 'MinMoreThanMax'
      ) {
        show('alert', strings.fields.alertMinMoreMax);
      }
      return;
    }
    if (validateRange(formData.data)) {
      show('alert', TechnicalAnalysisStrings[userInfo.language].attributes.attributeCell.errorMessages.invalidRange);
    } else {
      const newValues = formData.data.ELEMENTOS.map(item => {
        return {
          element: item.elemento,
          value: { max: item.max !== '-' ? item.max : '-', min: item.min !== '-' ? item.min : '-' },
          unit: item.unit,
        };
      });

      props.submitModals('STEEL', 'CHEMICAL_COMPOSITION', newValues);

      setModalToOpen(null);
    }
  };

  const handleRemove = (element: string) => {
    setMandatoryElements(mandatoryElements.filter(_el => element !== _el));
    setSelectedElements(selectedElements.filter(_el => element !== _el));
    if (normElements !== undefined) {
      setNormElements(normElements.filter(_el => element !== _el.element));
    }
  };

  const handleSelectedElements = (e: string, checked: boolean) => {
    if (checked) {
      setSelectedElements(prev => {
        return [...prev, e];
      });
    } else {
      setSelectedElements(selectedElements.filter(_el => e !== _el));
    }
  };

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const currentListElements = mandatoryElementsList.concat(selectedElements);
  const activeElements = [];
  allElements.forEach(item => {
    if (!currentListElements.includes(item.label)) {
      activeElements.push(item);
    }
  });

  let FAKE_INDEX = 0;

  return (
    <Modal preventOverlayClick opened={opened} onClose={() => handleClose()} small>
      <Scroll.Element>
        <Grid fluid>
          <Row mb>
            <Col xs={12}>
              <H1>{strings.title}</H1>
              {step === 2 && (
                <>
                  <Body>{strings.text}</Body>
                  <VSeparator />
                </>
              )}

              <>
                <CompositionTabForm step={step}>
                  <Form onSubmit={handleSubmit}>
                    <Table>
                      <TR>
                        <TH>
                          <Body>{strings.fields.element}</Body>
                        </TH>
                        <TH>
                          <Body>{strings.fields.min}</Body>
                        </TH>
                        <TH></TH>
                        <TH>
                          <Body>{strings.fields.max}</Body>
                        </TH>
                        <TH>
                          <Body>{strings.fields.unit}</Body>
                        </TH>
                      </TR>
                      {normElements &&
                        normElements.map((item: Element | string, index: number) => (
                          <Composition
                            index={FAKE_INDEX++}
                            element={typeof item === 'string' ? item : item.element}
                            min={typeof item === 'string' ? null : item?.value?.min}
                            max={typeof item === 'string' ? null : item?.value?.max}
                            unit={typeof item === 'string' ? '%' : item?.unit}
                            key={typeof item === 'string' ? item : item.element + index + 'n'}
                            onRemove={handleRemove}
                            strings={strings}
                          />
                        ))}
                      {selectedElements !== undefined &&
                        selectedElements.map((element: string, index: number) => (
                          <Composition
                            index={FAKE_INDEX++}
                            element={element}
                            min={props.data && props?.data[element]?.value?.min}
                            max={props.data && props?.data[element]?.value?.max}
                            unit={props.data && props?.data[element]?.unit}
                            key={element + (index + mandatoryElements.length)}
                            onRemove={handleRemove}
                            activeElements={activeElements}
                            setSelectedElements={setSelectedElements}
                            selectedElements={selectedElements}
                            strings={strings}
                          />
                        ))}
                    </Table>
                    <VSeparator />
                    <Divisor />
                    <VSeparator />
                    <Hbox vAlign='center'>
                      <Hbox.Item>
                        <ButtonText onClick={() => setStep(2)}>
                          <FaIcon.Plus size='1x' /> {strings.btnAdd}
                        </ButtonText>
                      </Hbox.Item>
                      <Hbox.Separator />
                      <Hbox.Item noGrow>
                        <Button kind='primary' expanded type='submit'>
                          {strings.btnSave}
                        </Button>
                      </Hbox.Item>
                    </Hbox>
                    <VSeparator />
                  </Form>
                </CompositionTabForm>

                <CompositionTabList step={step}>
                  <CompositionTabsWrapperStyled>
                    <CompositionTab tabIndex={tabIndex} labels={strings.tabs} handleTabChange={handleTabChange} />
                  </CompositionTabsWrapperStyled>

                  <CompositionContentWrapperStyled>
                    <VSeparator />
                    <CompositionList
                      strings={strings}
                      id={0}
                      numCols={6}
                      tabIndex={tabIndex}
                      listElements={elementsList?.simpleElements}
                      elements={mandatoryElements}
                      selectedElements={selectedElements}
                      normElements={normElements}
                      handleSelectedElements={handleSelectedElements}
                      loading={loadingElementList}
                      typeElements='simple'
                    />
                    <CompositionList
                      strings={strings}
                      id={1}
                      numCols={12}
                      tabIndex={tabIndex}
                      listElements={elementsList?.composedElements}
                      elements={mandatoryElements}
                      selectedElements={selectedElements}
                      normElements={normElements}
                      handleSelectedElements={handleSelectedElements}
                      loading={loadingElementList}
                      typeElements='composed'
                    />
                  </CompositionContentWrapperStyled>
                  <Divisor />
                  <VSeparator />
                  <Hbox hAlign='flex-end'>
                    <Hbox.Item noGrow>
                      <Button kind='secondary' expanded onClick={() => setStep(1)}>
                        {strings.btnBack}
                      </Button>
                    </Hbox.Item>
                    <Hbox.Separator />
                    <Hbox.Item noGrow>
                      <Button kind='primary' expanded onClick={() => setStep(1)}>
                        {strings.btnSelectedElements}
                      </Button>
                    </Hbox.Item>
                  </Hbox>
                  <VSeparator />
                </CompositionTabList>
              </>
            </Col>
          </Row>
        </Grid>
      </Scroll.Element>
    </Modal>
  );
};
