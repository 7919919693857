import styled from 'styled-components';
import { Breakpoint } from '@atomic/obj.constants';

export const CompositionTabsWrapperStyled = styled.div`
  width: 100%;
  ul {
    justify-content: space-between;
    li {
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0;
      text-align: center;
      a {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const CompositionContentWrapperStyled = styled.div`
  height: 410px;
  max-height: 410px;
  overflow: scroll;
  padding-right: 0px;
  @media all and (max-height: ${Breakpoint.md}em) {
    height: auto;
    max-height: 280px;
  }
  & > div {
    border-left: none;
    border-right: none;
    & > div {
      padding-right: 1rem;
      padding-left: 0.5rem;
    }
  }
`;

export const CompositionWrapper = styled.div`
  & > div > input {
    -moz-appearance: textfield;
    appearance: textfield;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
`;

export const CompositionSearchWrapperStyled = styled.div`
  button {
    cursor: text !important;
  }
`;

export const CompositionContainerStyled = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  column-count: ${props => props.numColumns};
  column-gap: 20px;
  width: 100%;
  display: block;
`;

export const CompositionItemStyled = styled.li`
  break-inside: avoid-column;
  margin-bottom: 0;
`;

interface PropsCompositionTab {
  step?: number;
}

export const CompositionTabForm = styled.div`
  display: ${(props: PropsCompositionTab) => (props.step === 1 ? 'block' : 'none')};
`;
export const CompositionTabList = styled.div`
  display: ${(props: PropsCompositionTab) => (props.step !== 1 ? 'block' : 'none')};
`;
