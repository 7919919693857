import { PayloadAction } from '@reduxjs/toolkit';
import { IBpOption, ISelectOption } from '@app/models/admin.model';
import { IAdminState } from '../admin.interfaces';
import { initialState } from './admin.initial';

export const reducers = {
  reset: () => {
    return initialState;
  },
  setUserFilterOption: (state: IAdminState, { payload }) => {
    state.userSearch.filterOption = payload;
  },
  setUserTextQuery: (state: IAdminState, { payload }) => {
    state.userSearch.textQuery = payload;
  },
  setClearUserFilterQuery: (state: IAdminState, { payload }) => {
    state.userSearch.textQuery = !payload && '';
    state.userSearch.selectedOptions = !payload && [];
  },
  setSelectedUserFilterOptions: (state: IAdminState, { payload }) => {
    state.userSearch.selectedOptions = payload;
  },
  setBpFilterOption: (state: IAdminState, { payload }) => {
    state.bpSearch.filterOption = payload;
  },
  setBpTextQuery: (state: IAdminState, { payload }) => {
    state.bpSearch.textQuery = payload;
  },
  setClearBPFilterQuery: (state: IAdminState, { payload }) => {
    state.bpSearch.textQuery = !payload && '';
    state.bpSearch.selectedOptions = !payload && [];
    state.bpFilter.selectedCorpGroupOptions = !payload && [];
  },
  setSelectedBPFilterOptions: (state: IAdminState, { payload }) => {
    state.bpSearch.selectedOptions = payload;
  },
  setBPFormSelectedOptions: (state: IAdminState, { payload }) => {
    state.bpFilter.selectedOptions = payload;
  },
  setBPFormGroupSelectedOptions: (state: IAdminState, { payload }) => {
    state.bpFilter.selectedCorpGroupOptions = payload;
  },
  setShowUserForm: (state: IAdminState, { payload }) => {
    state.showForm = payload;
  },
  setUser: (state: IAdminState, { payload }) => {
    state.user = payload;
  },
  setFormBpFilterOption: (state: IAdminState, { payload }) => {
    state.bpFilter.filterOption = payload;
  },
  setFormBpTextQuery: (state: IAdminState, { payload }) => {
    state.bpFilter.textQuery = payload;
  },
  setBpOptions: (state: IAdminState, { payload }) => {
    const bpOptions: IBpOption[] = [];
    const bpBPOptionsFormSelect: ISelectOption[] = [];
    const bpCorpGroupOptionsFormSelect: ISelectOption[] = [];
    const corpGroupSelect: ISelectOption[] = [];
    const salesOrgSelect: ISelectOption[] = [];

    payload.forEach(p => {
      bpOptions.push({
        code: p.customerId,
        name: p.customerName,
        id: p._id,
        selected: false,
      });

      if (p.customerId && !bpBPOptionsFormSelect.some(s => s.value === p.customerId)) {
        bpBPOptionsFormSelect.push({ label: `${p.customerId} - ${p.customerName}`, value: p._id });
      }

      if (p.customerId && p.corporateGroup && !bpCorpGroupOptionsFormSelect.some(s => s.value === p.corporateGroup)) {
        bpCorpGroupOptionsFormSelect.push({
          label: `${p.corporateGroup} - ${p.corporateGroupName}`,
          value: p.corporateGroup,
        });
      }

      if (p.corporateGroup && !corpGroupSelect.some(s => s.value === p.corporateGroup)) {
        corpGroupSelect.push({ label: `${p.corporateGroup} - ${p.corporateGroupName}`, value: p.corporateGroup });
      }

      if (p.salesOrganization && !salesOrgSelect.some(s => s.value === p.salesOrganization)) {
        salesOrgSelect.push({ label: p.salesOrganization, value: p.salesOrganization });
      }
    });

    state.bpOptions = bpOptions;
    state.bpBPOptionsFormSelect = bpBPOptionsFormSelect;
    state.bpCorpGroupOptionsFormSelect = bpCorpGroupOptionsFormSelect;
    state.corpGroupSelect = corpGroupSelect;
    state.salesOrgSelect = salesOrgSelect;
  },
  toogleSelectClient: (state: IAdminState, { payload }: PayloadAction<string>) => {
    const findIndex = state.bpOptions.findIndex(bp => bp.code === payload);
    state.bpOptions[findIndex].selected = !state.bpOptions[findIndex].selected;
  },
  setUserProfile: (state: IAdminState, { payload }) => {
    state.user.profile = payload;
  },
  removeSelectedBpOption: (state: IAdminState, { payload }) => {
    const findIndex = state.bpOptions.findIndex(bp => bp.code === payload);
    state.bpOptions[findIndex].selected = false;
  },
  updateSelectedBPOption: (state: IAdminState, { payload }) => {
    payload.forEach(p => {
      const findIndex = state.bpOptions.findIndex(bp => bp.code === p);
      state.bpOptions[findIndex].selected = true;
    });
  },
  clearSelectedBPOption: (state: IAdminState, { payload }) => {
    state.bpOptions = payload;
  },
  clearUser: (state: IAdminState, { payload }) => {
    state.user = {
      _id: payload,
      oktaUid: payload,
      name: payload,
      email: payload,
      language: payload,
      applications: payload,
      lastLogin: payload,
      created: {
        at: payload,
        by: {
          name: payload,
          email: payload,
        },
      },
      profile: {
        _id: payload,
        profile: '',
        tabs: [],
      },
      bps: [],
      isAdmin: false,
    };
  },
  clearAllUsers: (state: IAdminState, { payload }) => {
    state.allUsers = payload;
  },
  clearAllBPs: (state: IAdminState, { payload }) => {
    state.allBPs = payload;
  },
  clearAllNPSUsers: (state: IAdminState, { payload }) => {
    state.allNPSUsers = payload;
  },
  setEppRoleConfig: (state: IAdminState, { payload }) => {
    state.eppRoleConfig = payload;
  },
  setPedRoleConfig: (state: IAdminState, { payload }) => {
    state.pedRoleConfig = payload;
  },
  setRtcRoleConfig: (state: IAdminState, { payload }) => {
    state.rtcRoleConfig = payload;
  },
  setGqRoleConfig: (state: IAdminState, { payload }) => {
    state.gqRoleConfig = payload;
  },
  setAdminRoleConfig: (state: IAdminState, { payload }) => {
    state.adminRoleConfig = payload;
  },
  setSellerRoleConfig: (state: IAdminState, { payload }) => {
    state.sellerRoleConfig = payload;
  },
  setScmRoleConfig: (state: IAdminState, { payload }) => {
    state.scmRoleConfig = payload;
  },
  setSquadRoleConfig: (state: IAdminState, { payload }) => {
    state.squadRoleConfig = payload;
  },
  setSurrogateConfig: (state: IAdminState, { payload }) => {
    state.surrogateConfig = payload;
  },
  setLackOfChargeConfig: (state: IAdminState, { payload }) => {
    state.lackOfChargeConfig = payload;
  },
  setSchedulingCompleteConfig: (state: IAdminState, { payload }) => {
    state.schedulingCompleteConfig = payload;
  },
  setShowConfirmModal: (state: IAdminState, { payload }) => {
    state.showConfirmModal = payload;
  },
  setShowDeleteModal: (state: IAdminState, { payload }) => {
    state.showDeleteModal = payload;
  },
  setIsEditing: (state: IAdminState, { payload }) => {
    state.isEditing = payload;
  },
  setUserActiveId: (state: IAdminState, { payload }) => {
    state.userSearch.activeId = payload;
  },
  setBPActiveId: (state: IAdminState, { payload }) => {
    state.bpSearch.activeId = payload;
  },
  setNPSUserFilterOption: (state: IAdminState, { payload }) => {
    state.npsUserSearch.filterOption = payload;
  },
  setNPSUserTextQuery: (state: IAdminState, { payload }) => {
    state.npsUserSearch.textQuery = payload;
  },
  setNPSClearUserFilterQuery: (state: IAdminState, { payload }) => {
    state.npsUserSearch.textQuery = !payload && '';
    state.npsUserSearch.selectedOptions = !payload && [];
  },
  setSelectedNPSGroup: (state: IAdminState, { payload }) => {
    state.selectedNPSGroup = payload;
  },
  setNPSUserActiveId: (state: IAdminState, { payload }) => {
    state.npsUserSearch.activeId = payload;
  },
  setSelectedNPSUserFilterOptions: (state: IAdminState, { payload }) => {
    state.npsUserSearch.selectedOptions = payload;
  },
};
