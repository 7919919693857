import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { H4, InputLabel } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { rangeLimits } from '../../form.contants';
import { FormContext } from '../../form.context';
import { FormResultEditOptions } from './form-result-edit-options';
import { FormResultAttributeWrapper, FormResultOptionsWrapper } from './form-result.component.style';

interface CompositionItems {
  element: string;
  value: {
    min?: any;
    max?: any;
  };
  unit: string;
}
interface CompositionTableProps {
  attrKey: string;
  stepKey: string;
  attribute: {
    display: boolean;
    key: string;
    label?: string;
    mandatory?: boolean;
    permissions?: {
      visible: boolean;
      editable: boolean;
    };
    type: string;
    readBy: string;
    value: CompositionItems[];
  };
}

export const CompositionTable: React.FC<CompositionTableProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { focusAttribute } = React.useContext(FormContext);

  const hasOneDecimalPlace = (value: number) => {
    const decimalPart = value.toString().split('.');
    if (Array.isArray(decimalPart) && decimalPart.length > 1) {
      return decimalPart[1].length === 1;
    }
    return false;
  };

  const formatStringMaxValue = (val: any) => {
    if (val && val !== '-') {
      if (val === 0 || val >= 99) {
        return 99;
      }
      if (typeof val === 'number' && hasOneDecimalPlace(val)) {
        return val.toFixed(2).toString();
      }
      return val.toString().replace(',', '.');
    } else {
      return 99;
    }
  };

  const formatStringMinValue = (val: any) => {
    if (val && val !== '-') {
      if (val === 0 || val >= 99) {
        return rangeLimits.dash;
      }
      if (typeof val === 'number' && hasOneDecimalPlace(val)) {
        return val.toFixed(2).toString();
      }
      return val ? val.toString().replace(',', '.') : '-';
    } else {
      return '-';
    }
  };

  return props.attribute?.permissions.visible ? (
    <FormResultAttributeWrapper
      data-testid={`analysisResume${props.attrKey}`}
      modified={props.attribute?.readBy === 'nlu'}
      onClick={() => focusAttribute(props.attrKey)}
    >
      <Hbox.Item>
        <FormResultOptionsWrapper>
          <InputLabel>
            {props.attribute?.label}{' '}
            {props.attribute?.readBy === 'nlu' ? (
              <span>{` ${QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}`}</span>
            ) : (
              props.attribute?.readBy === 'GERDAU' && ' *'
            )}
          </InputLabel>
          <FormResultEditOptions
            stepKey={props.stepKey}
            editable={props.attribute.permissions.editable}
            attrKey={props.attrKey}
            type={props.attribute.type}
            attribute={props.attribute}
          />
        </FormResultOptionsWrapper>
      </Hbox.Item>
      <Table>
        <TR>
          <TD />
          <TD>
            <H4>Min</H4>
          </TD>
          <TD>
            <H4>Max</H4>
          </TD>
          <TD>
            <H4>Unit</H4>
          </TD>
        </TR>
        {props?.attribute?.value &&
          props.attribute.value.map(item => (
            <TR key={item?.element}>
              <TD>
                <H4>{item?.element}</H4>
              </TD>
              <TD>{formatStringMinValue(item?.value?.min)}</TD>
              <TD>{formatStringMaxValue(item?.value?.max)}</TD>
              <TD>{item?.unit}</TD>
            </TR>
          ))}
      </Table>
      <VSeparator />
    </FormResultAttributeWrapper>
  ) : (
    <></>
  );
};
