import styled from 'styled-components';
import { Badge } from '@atomic/atm.badge';
import { Border, Spacing, FontSize, FontWeight, Color, BoxHeight, BrandColor } from '@atomic/obj.constants';
import { BASE_GRID_DISCOUNT, BASE_GRID_DISCOUNT_WITH_ACTION_BAR } from './order-tabs.constants';

export const OrderBadge = styled(Badge)`
  padding: 0 ${Spacing.XSmall};
  border-radius: ${Border.RadiusLarge};
  line-height: 20px;
`;

export const OrderTabStyled = styled.div.attrs(() => ({ className: 'ag-theme-balham' }))`
  .ag-root-wrapper-body {
    height: 60.2vh;
  }

  .ag-cell-value {
    text-align: center;
  }

  .ag-cell-value[col-id='dateDeliveryForecast'] {
    overflow: visible !important;
  }

  @media all and (max-width: 1280px) {
    .ag-root-wrapper-body {
      height: 51.5vh;
    }
  }
`;

export const OrderTabErrorStyled = styled.div`
  height: 60.2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  color: ${Color.GrayXDark};
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.Medium};
  line-height: ${FontSize.XLarge};
`;

export const AgGridOrderWrapperStyled = styled.div`
  padding-top: ${Spacing.Small};
  height: ${({ showActionBar }) =>
    `calc(100vh - ${showActionBar ? BASE_GRID_DISCOUNT_WITH_ACTION_BAR : BASE_GRID_DISCOUNT} )`};
`;

export const SidebarWrapper = styled.div`
  display: flex;
  width: 300px;
`;

export const TabsWrapper = styled.div`
  display: flex;
`;

export const SeparatorBar = styled.div`
  border-left: 1px solid ${Color.Gray};
  height: ${BoxHeight.Small};
`;

export const CriticalFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: ${Spacing.Small};
  position: relative;
  top: -5px;
  height: ${BoxHeight.Small};
`;

interface CriticalFilterButtonProps {
  checked: boolean;
}

export const CriticalFilterButton = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props: CriticalFilterButtonProps) =>
    props.checked ? `${BrandColor.Blue}` : `${BrandColor.LightBlue}`};
  color: ${(props: CriticalFilterButtonProps) => (props.checked ? `${Color.GrayXLight}` : `${BrandColor.Blue}`)};
  border-radius: ${FontSize.Small};
  margin-left: ${Spacing.XSmall};
  padding: ${Spacing.XSmall} ${Spacing.Medium} ${Spacing.XSmall} ${Spacing.Medium};
  font-family: 'Open Sans', sans-serif;
  font-size: ${FontSize.XSmall};
  cursor: pointer;
`;

export const TagFilterWrapper = styled.div`
  display: flex;
  margin-left: ${Spacing.XSmall};
`;
