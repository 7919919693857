import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../admin.constants';
import { IAdminState } from '../admin.interfaces';
import { initialState } from './admin.initial';
import { reducers } from './admin.reducers';
import { addThunkReducers } from './thunk/admin.thunks';

export const adminSlice = createSlice<IAdminState, SliceCaseReducers<IAdminState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addThunkReducers,
});

export const {
  setUserFilterOption,
  setUserTextQuery,
  setClearUserFilterQuery,
  setSelectedUserFilterOptions,
  setBpFilterOption,
  setBpTextQuery,
  setClearBPFilterQuery,
  setSelectedBPFilterOptions,
  setBPFormSelectedOptions,
  setBPFormGroupSelectedOptions,
  setShowUserForm,
  setUser,
  setFormBpFilterOption,
  setFormBpTextQuery,
  setBpOptions,
  toogleSelectClient,
  setUserProfile,
  removeSelectedBpOption,
  updateSelectedBPOption,
  clearSelectedBPOption,
  clearUser,
  clearAllUsers,
  clearAllBPs,
  clearAllNPSUsers,
  setEppRoleConfig,
  setPedRoleConfig,
  setRtcRoleConfig,
  setGqRoleConfig,
  setAdminRoleConfig,
  setSellerRoleConfig,
  setScmRoleConfig,
  setSquadRoleConfig,
  setSurrogateConfig,
  setLackOfChargeConfig,
  setSchedulingCompleteConfig,
  setShowConfirmModal,
  setShowDeleteModal,
  setIsEditing,
  setUserActiveId,
  setBPActiveId,
  setNPSUserFilterOption,
  setNPSUserTextQuery,
  setNPSClearUserFilterQuery,
  setSelectedNPSGroup,
  setNPSUserActiveId,
  setSelectedNPSUserFilterOptions,
} = adminSlice.actions;

export const adminReducer = adminSlice.reducer;
