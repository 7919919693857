import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { ExtraDimensionData } from '@app/data/http/quote-params.dto';
import { Button } from '@atomic/atm.button';
import { Divisor } from '@atomic/atm.divisor';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import { Body, ButtonText, H1 } from '@atomic/atm.typography';
import { Table, TD, TH, TR } from '@atomic/mol.table';
import { Hbox, Separator } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { FormStrings } from '../quote/form/form.string';
import { ChatStrings } from './chat.string';
import { DiameterRowWrapper } from './modal-diameter.component.style';
import { JominyContentWrapperStyled } from './modal-jominy.component.style';
export interface DimensionsContext {
  EXTRA_DIMENSION: ExtraDimensionData[];
  OTHERS_ATTRIBUTES: any;
}
interface DiamenterRowProps {
  forma?: string;
  noLength?: boolean;
  mainDiameterSide?: number;
  mainThickness?: number;
  mainWidth?: number;
  mainConsumo?: number;
  mainComprimentoMin?: number;
  mainComprimentoMax?: number;
  index: number;
  item: string;
  strings: any;
  handleRemove: (element: string) => void;
}

const DiameterRow: React.FC<DiamenterRowProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const form2Dimensions = ['RETANGULAR', 'CHATO'].includes(props.forma);
  const stringsNewForm = FormStrings[userInfo.language].newForm.messages;

  const convertConversationId = name => {
    const convertedName = name
      .replaceAll(' (Principal)', '')
      .replaceAll(' (Main)', '')
      .replaceAll(' ', '_')
      .toUpperCase();
    return convertedName;
  };

  const [min, setMin] = React.useState(null);
  const [max, setMax] = React.useState(null);

  const handleValue = (val, field) => {
    if (field === 'min') {
      setMin(val);
    } else if (field === 'max') {
      setMax(val);
    }
  };
  const handler = (): void => {
    if (
      document.activeElement !== null &&
      (document.activeElement as HTMLInputElement).type === 'number' &&
      (document.activeElement as HTMLInputElement).classList.contains('noscroll')
    ) {
      (document.activeElement as HTMLInputElement).blur();
    }
  };
  document.addEventListener('wheel', handler, { passive: true } as AddEventListenerOptions);

  return (
    <TR>
      <TD>
        <Hbox.Item>
          <Hbox vAlign='flex-end'>
            <Hbox.Item noGrow>
              <Body>{props.item}</Body>
              <Form.Field
                name={`EXTRA_DIMENSIONS[${props.index}].dimensionId`}
                initialValue={convertConversationId(props.item)}
              >
                <TextField type='hidden' small />
              </Form.Field>
            </Hbox.Item>
          </Hbox>
        </Hbox.Item>
      </TD>
      <TD>
        <Hbox.Item>
          <Hbox vAlign='flex-end'>
            {form2Dimensions ? (
              <>
                <Hbox.Item noGrow>
                  <Form.Field
                    name={`EXTRA_DIMENSIONS[${props.index}].largura`}
                    hideErrorCaption
                    label={props.index === 0 && props.strings.largura}
                    validators={[Validators.Required()]}
                    initialValue={props.index === 0 && props.mainWidth}
                  >
                    <TextField type='number' noArrows={true} small className='noscroll' />
                  </Form.Field>
                </Hbox.Item>
                <Body>X</Body>

                <Hbox.Item noGrow>
                  <Form.Field
                    name={`EXTRA_DIMENSIONS[${props.index}].espessura`}
                    label={props.index === 0 && props.strings.espessura}
                    hideErrorCaption
                    validators={[Validators.Required()]}
                    initialValue={props.index === 0 && props.mainThickness}
                  >
                    <TextField type='number' noArrows={true} small className='noscroll' />
                  </Form.Field>
                </Hbox.Item>
                <Separator />
              </>
            ) : (
              <>
                <Hbox.Item noGrow>
                  <Form.Field
                    name={`EXTRA_DIMENSIONS[${props.index}].diametro`}
                    hideErrorCaption
                    label={props.index === 0 && props.strings.diameter}
                    validators={[Validators.Required()]}
                    initialValue={props.index === 0 && props.mainDiameterSide}
                  >
                    <TextField type='number' noArrows={true} className='noscroll' />
                  </Form.Field>
                </Hbox.Item>
                <Separator />
              </>
            )}
            <Body>mm</Body>
          </Hbox>
        </Hbox.Item>
      </TD>
      {!props.noLength ? (
        <TD>
          <Hbox.Item>
            <Hbox vAlign='flex-end'>
              <Hbox.Item noGrow>
                <Form.Field
                  name={`EXTRA_DIMENSIONS[${props.index}].comprimentoMin`}
                  label={props.index === 0 && props.strings.min}
                  hideErrorCaption
                  validators={[Validators.Required(), Validators.MinMoreThanMax([min, max], stringsNewForm.alertMax)]}
                  onValueChange={value => handleValue(parseFloat(value), 'min')}
                  initialValue={props.index === 0 && props.mainComprimentoMin}
                >
                  <TextField type='number' noArrows={true} small className='noscroll' />
                </Form.Field>
              </Hbox.Item>

              <Body>{props.strings.to}</Body>

              <Hbox.Item noGrow>
                <Form.Field
                  name={`EXTRA_DIMENSIONS[${props.index}].comprimentoMax`}
                  hideErrorCaption
                  label={props.index === 0 && props.strings.max}
                  validators={[Validators.Required(), Validators.MinMoreThanMax([min, max], stringsNewForm.alertMax)]}
                  onValueChange={value => handleValue(parseFloat(value), 'max')}
                  initialValue={props.index === 0 && props.mainComprimentoMax}
                >
                  <TextField type='number' noArrows={true} small className='noscroll' />
                </Form.Field>
              </Hbox.Item>
              <Body>mm</Body>
            </Hbox>
          </Hbox.Item>
        </TD>
      ) : null}

      <TD>
        <Hbox.Item>
          <Hbox vAlign='flex-end'>
            <Hbox.Item noGrow>
              <Form.Field
                name={`EXTRA_DIMENSIONS[${props.index}].consumo`}
                hideErrorCaption
                validators={[Validators.Required()]}
                label={props.index === 0 && '.'}
                initialValue={props.index === 0 && props.mainConsumo}
              >
                <TextField type='number' noArrows={true} className='noscroll' />
              </Form.Field>
            </Hbox.Item>
            <Body> {props.strings.consumoTon} </Body>
          </Hbox>
        </Hbox.Item>
      </TD>
      <TD>
        {props.index > 0 ? (
          <Button size='small' kind='link' onClick={() => props.handleRemove(props.item)} title={props.strings.remove}>
            <FaIcon.Trash />
          </Button>
        ) : null}
      </TD>
    </TR>
  );
};

interface ModalDiameterProps {
  forma?: string;
  product?: string;
  mainDiameterSide?: number;
  mainThickness?: number;
  mainWidth?: number;
  mainConsumo?: number;
  mainComprimentoMin?: number;
  mainComprimentoMax?: number;
  openTableType: string;
  data: DimensionsContext;
  onSubmit?: (data: ExtraDimensionData[], key: string) => void;
  onClose: () => void;
}

export const ModalDiameter: React.FC<ModalDiameterProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language].modalDiameter;
  const [opened, setOpened] = React.useState(false);
  const [newDiameters, setNewDiameters] = React.useState([`${strings.bitola} 1 (${strings.main})`]);
  const [numBitolas, setNumBitola] = React.useState(1);
  const contentRef = React.useRef(null);
  const noLength = ['FIO_MAQUINA', 'ARAME', 'LINGOTE'].includes(props.product);

  const checkBitolas = attributes => {
    const numTotalBitolas = [];
    attributes.map(attr => {
      if (attr.LABEL.includes('Bitola')) {
        numTotalBitolas.push(attr.LABEL);
      }
    });

    const lastBitola = numTotalBitolas.pop();
    return lastBitola ? parseInt(lastBitola.split(' ')[1]) : 1;
  };

  React.useEffect(() => {
    if (props.openTableType.toLowerCase() === 'diameter') {
      if (props.data?.OTHERS_ATTRIBUTES) {
        setNumBitola(checkBitolas(props.data?.OTHERS_ATTRIBUTES));
      }
      setOpened(true);
    }
  }, [props.openTableType]);

  const handleSubmit = (data: FormData<ExtraDimensionData[]>) => {
    if (Object.keys(data.error).length > 0) {
      return;
    }

    setNewDiameters([`${strings.bitola} 1 (${strings.main})`]);
    setNumBitola(1);
    props.onSubmit(data.data, 'EXTRA_DIMENSIONS');
    setOpened(false);
  };

  const handleRemove = (element: string) => {
    setNewDiameters(newDiameters.filter(_el => element !== _el));
  };

  const handleClose = () => {
    setOpened(false);
    props.onClose();
  };

  const handleNewPointClick = () => {
    setNumBitola(numBitolas + 1);
    setNewDiameters(prev => [...prev, `${strings.bitola} ${numBitolas + 1}`]);
  };

  return (
    <Modal preventOverlayClick medium opened={opened} onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <H1>{strings.title}</H1>
              <VSeparator />
            </Col>
          </Row>
          <JominyContentWrapperStyled>
            <Row mt mb>
              <Col xs={12}>
                <DiameterRowWrapper>
                  <Table>
                    <TR>
                      <TH></TH>
                      <TH>{strings.bitola} (mm)</TH>
                      {!noLength && <TH>{strings.comprimento} (mm)</TH>}
                      <TH>
                        {strings.consumo} ({strings.consumoTon})
                      </TH>
                      <TH></TH>
                    </TR>

                    {newDiameters.map((item: string, index: number) => (
                      <DiameterRow
                        strings={strings}
                        forma={props.forma}
                        noLength={noLength}
                        mainDiameterSide={props.mainDiameterSide}
                        mainThickness={props.mainThickness}
                        mainWidth={props.mainWidth}
                        mainConsumo={props.mainConsumo}
                        mainComprimentoMin={props.mainComprimentoMin}
                        mainComprimentoMax={props.mainComprimentoMax}
                        key={item}
                        item={item}
                        index={index}
                        handleRemove={handleRemove}
                      />
                    ))}
                  </Table>
                </DiameterRowWrapper>
              </Col>
            </Row>
            <div ref={contentRef} />
          </JominyContentWrapperStyled>
          <Divisor />
          <VSeparator />
          <Hbox vAlign='center'>
            <Hbox.Item>
              <ButtonText onClick={() => handleNewPointClick()}>
                <FaIcon.Plus size='1x' /> {strings.addMeasure}
              </ButtonText>
            </Hbox.Item>

            <Hbox.Separator />
            <Hbox.Item noGrow>
              <Button kind='primary' expanded type='submit'>
                {strings.save}
              </Button>
            </Hbox.Item>
          </Hbox>
          <VSeparator />
        </Grid>
      </Form>
    </Modal>
  );
};

ModalDiameter.defaultProps = {
  openTableType: '',
};
