/* eslint-disable prettier/prettier */
import { format } from 'date-fns';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ExcelType, ProfileTypes } from '@app/models/admin.model';
import { capitalizeFirstLetter } from '@app/utils/string-utils';
import { NPSUserStatus } from '../admin-nps/utils/utils';

const mapUsersDataToExcelData = apiData => {
  const mappedData = [];

  apiData.forEach(item => {
    mappedData.push({
      Perfil: ProfileTypes[capitalizeFirstLetter(item.profile.toLocaleLowerCase())],
      Nome: item.name,
      Email: item.email,
      UID: item.oktaUid,
      'Criado por': item.created.by.name,
      'Criado em': item.created.at.dateFormat,
      'Ultimo login': item.lastLogin.dateFormat,
      'Permissionamentos por aba': item.tabs.join(),
      BPs: getUserBPs(item.clients),
      'Grupo NPS': item.npsGroup,
    });
  });

  return mappedData;
};

const mapBPsDataToExcelData = apiData => {
  const mappedData = [];

  apiData.forEach(item => {
    mappedData.push({
      Nome: item.name,
      'Nº do BP': item.bp,
      'Grupo Cliente': item.corporateGroup,
      'Nome Grupo Cliente': item.corporateGroupName,
      'Qtd. Usuários': item.users.length,
      'Usuários: ': getBPUsers(item.users),
    });
  });

  return mappedData;
};

const mapNPSUsersDataToExcelData = (apiData, npsGroup) => {
  const mappedData = [];

  apiData.forEach(item => {
    mappedData.push({
      Nome: item.name,
      Email: item.email,
      Perfil: ProfileTypes[capitalizeFirstLetter(item.profile.toLocaleLowerCase())],
      'Corp. group': item.corpGroup.join(),
      Status: NPSUserStatus[capitalizeFirstLetter(item.status)],
      'Última resposta': format(new Date(item.lastResponse), 'dd/MM/yyyy HH:mm:ss'),
      'Group NPS': npsGroup,
    });
  });

  return mappedData;
};

const getUserBPs = bps => {
  const bpList = [];

  if (bps?.length === 0) {
    return '';
  }

  bps.forEach(bp => {
    const bpString = `BP: ${bp.name} - ${capitalizeFirstLetter(bp.type)} Nº do BP: ${bp.bp}`;
    bpList.push(bpString);
  });

  return bpList.join();
};

const getBPUsers = users => {
  const userList = [];

  if (users?.length === 0) {
    return '';
  }

  users.forEach(u => {
    const userString = `Perfil: ${ProfileTypes[capitalizeFirstLetter(u.profile.toLocaleLowerCase())]} - Nome: ${
      u.name
    } - UID: ${u.oktaUid} - Criado por: ${u.created.by.name} - Criado em: ${u.created.at.dateFormat} - Ultimo login: ${
      u.lastLogin.dateFormat
    }`;
    userList.push(userString);
  });

  return userList.join();
};

export const exportToCSV = (apiData, fileName, excelType) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(
    excelType === ExcelType.Users ? mapUsersDataToExcelData(apiData) : mapBPsDataToExcelData(apiData),
  );
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const exportNPSDataToCSV = (apiData, fileName, npsGroup) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(mapNPSUsersDataToExcelData(apiData, npsGroup));
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
