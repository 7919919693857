import React, { useCallback, useEffect, useRef } from 'react';
import { handleDotStyle } from '../side-bar-filters/sidebar-filters.utils';
import {
  InputRangeMax,
  InputRangeMin,
  RangeWrapper,
  SlidarTrack,
  Slider,
  SliderLeftValue,
  SliderRange,
  SliderRightValue,
} from './range-slider.style';

interface IRangeSliderProps {
  minLimit: number;
  maxLimit: number;
  minVal: number;
  maxVal: number;
  setMinVal: (val: number) => void;
  setMaxVal: (val: number) => void;
  disabled?: boolean;
}

export const RangeSlider: React.FC<IRangeSliderProps> = ({
  minLimit,
  maxLimit,
  minVal,
  maxVal,
  setMinVal,
  setMaxVal,
  disabled = false,
}) => {
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  const getPercent = useCallback(value => Math.round(((value - minLimit) / (maxLimit - minLimit)) * 100), [
    minLimit,
    maxLimit,
  ]);

  useEffect(() => {
    if (maxValRef.current) {
      handleDotStyle(true, maxValRef.current);
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  useEffect(() => {
    if (minValRef.current) {
      handleDotStyle(false, minValRef.current);
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  return (
    <RangeWrapper>
      <InputRangeMin
        type='range'
        disabled={disabled}
        min={minLimit}
        max={maxLimit}
        value={minVal}
        ref={minValRef}
        onChange={event => {
          const value = Math.min(+event.target.value, maxVal - 1);
          setMinVal(value);
          event.target.value = value.toString();
        }}
      />
      <InputRangeMax
        type='range'
        disabled={disabled}
        min={minLimit}
        max={maxLimit}
        value={maxVal}
        ref={maxValRef}
        onChange={event => {
          const value = Math.max(+event.target.value, minVal + 1);
          setMaxVal(value);
          event.target.value = value.toString();
        }}
      />

      <Slider>
        <SlidarTrack />
        <SliderRange ref={range} disabled={disabled} />
        {!disabled && (
          <>
            <SliderLeftValue>{minVal}</SliderLeftValue>
            <SliderRightValue>{maxVal}</SliderRightValue>
          </>
        )}
      </Slider>
    </RangeWrapper>
  );
};
