import { useQueries, UseQueryOptions } from '@tanstack/react-query';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { IsaExternalRoles, Stages } from '@app/models/quote.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { Divisor } from '@atomic/atm.divisor';
import { TextAreaField, TextField } from '@atomic/atm.text-field';
import { H3 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { editPlantsSeller } from '../hooks/useEditPlantsSeller';
import { useUsersByProfilesAllData } from '../hooks/useUsersByProfiles';
import { TechnicalAnalysisContext } from '../technical-analysis.context';
import { EmailFieldsComponent } from './technical-analysis-status-field.component';
import { mapSKU, mapTechnicalAnalysisEmails } from './technical-analysis-status.mapper';

interface TechnicalAnalysisStatusModalProps {
  loading: boolean;
  opened: boolean;
  data: Stages;
  onClose: () => void;
  onSubmit: (key: string, data: TechnicalAnalysisStatusData) => void;
  onSubmitSellerToEpp: (key: string, data: TechnicalAnalysisStatusData) => void;
  extraNotifications: ExtraNotifications;
  analysisType?: string;
  totalSimilarMaterial: number;
}

export interface TechnicalAnalysisStatusData {
  emails: string[];
  observation?: string;
  observationClient?: string;
  APQP?: string;
  PE?: string | number;
  skus?: string[];
  partNumber?: string | number;
  FMEAS?: string;
  FMEAS_EDIT?: string;
  NEWPRODUCTEPP?: string;
  NEWPRODUCTEPPJUSTIFY?: string;
  skuSimilar?: {
    code?: string;
    price?: number;
    observation?: string;
  };
}

interface ExtraNotifications {
  APQP?: string;
  PE?: string | number;
  FMEAS?: string;
  FMEAS_EDIT?: string;
  NEWPRODUCTEPP?: string;
  NEWPRODUCTEPPJUSTIFY?: string;
}

export interface TechnicalAnalysisStatusModalFormData {
  observation?: string;
  observationClient?: string;
  email?: string;
  emailEpp?: string;
  emailPed?: string;
  emailRtc?: string;
  emailClient?: string;
  emailGq?: string;
  emailSeller?: string;
  APQP?: string;
  PE?: string | number;
  sku?: string;
  FMEAS?: string;
  FMEAS_EDIT?: string;
  NEWPRODUCTEPP?: string;
  NEWPRODUCTEPPJUSTIFY?: string;
  skuSimilar?: string;
  skuSimilarPrice?: number;
  skuObservation?: string;
}

export const TechnicalAnalysisStatusModal: React.FC<TechnicalAnalysisStatusModalProps> = props => {
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const { analysis, analysisId, setHasTokenChecked } = React.useContext(TechnicalAnalysisContext);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const [startPlantSeller, setStartPlantSeller] = React.useState(false);
  const strings = TechnicalAnalysisStrings[userInfo.language].status.statusModal;

  const [usersList, setUsersList] = React.useState({
    epp: [],
    rtc: [],
    ped: [],
    client: [],
    gq: [],
    seller: [],
  });

  const mapsList = (userList, profile) => {
    return {
      [profile.toLowerCase()]: userList.filter(u => u.profile === IsaExternalRoles[profile]).map(u => u.email),
    };
  };

  const { mutate: getUsers, isLoading: loadingListUsers } = useUsersByProfilesAllData(
    handleGetUsersSuccess,
    handleGetUsersError,
  );

  useQueries({
    queries: analysis?.plants
      .map(plant => {
        if (
          startPlantSeller &&
          !plant.review.isRequested &&
          (plant.key === 'charqueadas' || plant.key === 'pindamonhangaba')
        ) {
          const queryConfig: UseQueryOptions<any, any> = {
            queryKey: ['editPlant', plant.key],
            queryFn: () =>
              editPlantsSeller({
                accessToken: token,
                authorizationToken: azureToken,
                language: userInfo.language,
                plant: plant,
                analysisId: analysisId,
              }),
          };
          return queryConfig;
        } else {
          return null;
        }
      })
      .filter(Boolean),
  });

  function handleGetUsersError(data) {
    show('alert', data.description);
  }

  function handleGetUsersSuccess(data) {
    setUsersList(prevValue => ({ ...prevValue, ...mapsList(data.data, data.profile) }));
  }

  React.useEffect(() => {
    if (props?.data) {
      props?.data?.notification?.profiles.forEach(profile => {
        getUsers({
          language: userInfo.language,
          profile,
          bp: analysis.bp,
          authorizationToken: azureToken,
          accessToken: token,
        });
      });
    }
  }, [props.opened]);

  const extraNotifications = props.extraNotifications;

  const moneyMask = (value: string) => {
    value = value
      .replace('.', '')
      .replace(',', '')
      .replace(/\D/g, '');

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat('pt-BR', options).format(parseFloat(value) / 100);

    return result;
  };

  const handleSubmit = (form: FormData<TechnicalAnalysisStatusModalFormData>) => {
    if (!(Object.keys(form.error).length > 0)) {
      dispatch(validateTokens()).then(() => {
        const emailCheck = email => {
          return email;
        };
        const emailList = [
          form.data.email,
          form.data.emailEpp,
          form.data.emailPed,
          form.data.emailRtc,
          form.data.emailClient,
          form.data.emailGq,
          form.data.emailSeller,
        ].filter(emailCheck);

        const payload = {
          emails: mapTechnicalAnalysisEmails(emailList),
          skus: mapSKU(form.data?.sku),
          observation: form.data.observation,
          observationClient: form.data.observationClient,
          APQP: extraNotifications.APQP,
          PE: extraNotifications.PE,
          FMEAS: extraNotifications.FMEAS,
          FMEAS_EDIT: extraNotifications.FMEAS_EDIT,
          NEWPRODUCTEPP: extraNotifications.NEWPRODUCTEPP,
          NEWPRODUCTEPPJUSTIFY: extraNotifications.NEWPRODUCTEPPJUSTIFY,
        };

        if (form.data?.skuSimilar) {
          Object.assign(payload, {
            skuSimilar: {
              code: form.data?.skuSimilar,
              price: form.data?.skuSimilarPrice,
              observation: form.data?.skuObservation,
            },
          });
        }

        if (userInfo.profile.profile === 'seller' && props.data.key === 'UNDER_ANALYSIS_EPP') {
          setStartPlantSeller(true);
          props.onSubmitSellerToEpp(props.data.key, { ...payload });
        } else {
          props.onSubmit(props.data.key, { ...payload });
        }

        props.onClose();
        setHasTokenChecked(true);
      });
    }
  };

  const handleClose = () => {
    if (!props.loading) {
      props.onClose();
    }
  };

  return (
    <Modal opened={props.opened} onClose={handleClose}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <VSeparator />
            <H3>{strings.changeStatus(props.data?.label)}</H3>
            <VSeparator />
            <Form onSubmit={handleSubmit}>
              {!!props.data && usersList && (
                <EmailFieldsComponent
                  loading={loadingListUsers}
                  users={usersList}
                  notification={props.data.notification}
                />
              )}
              <VSeparator small />
              {props.data?.key
                ? (props.data.key === 'FINALIZED' || props.data.key === 'FINALIZED_WITH_DEROGATION') && (
                    <>
                      <Divisor />
                      <Separator />
                      <Form.Field
                        validators={[
                          Validators.CheckSkusIsNumber(strings.skuField.alertSkuNumber),
                          Validators.CheckSkusFormat(strings.skuField.alertSkuFormat),
                        ]}
                        name='sku'
                        label={`${strings.skuField.label} - ${strings.skuField.labelOptional}`}
                      >
                        <TextField data-testid='skuField' placeholder={strings.skuField.placeholder} />
                      </Form.Field>
                      <VSeparator />

                      {analysis.flowType === 'NEW_PRODUCT' && (
                        <Row middle='xs'>
                          <Col xs={6}>
                            {props.totalSimilarMaterial === 0 && analysis.flowType === 'NEW_PRODUCT' ? (
                              <Form.Field
                                validators={[
                                  Validators.Required(strings.skuSimilar.alertMandatory),
                                  Validators.CheckSkusIsNumber(strings.skuSimilar.alertSkuNumber),
                                  Validators.CheckSkusFormat(strings.skuSimilar.alertSkuFormat),
                                ]}
                                name='skuSimilar'
                                label={strings.skuSimilar.label}
                              >
                                <TextField
                                  data-testid='skuSimilar'
                                  placeholder={strings.skuSimilar.placeholder}
                                  maxLength={9}
                                />
                              </Form.Field>
                            ) : (
                              <Form.Field
                                validators={[
                                  Validators.CheckSkusIsNumber(strings.skuSimilar.alertSkuNumber),
                                  Validators.CheckSkusFormat(strings.skuSimilar.alertSkuFormat),
                                ]}
                                name='skuSimilar'
                                label={strings.skuSimilar.label}
                              >
                                <TextField
                                  data-testid='skuSimilar'
                                  placeholder={strings.skuSimilar.placeholder}
                                  maxLength={9}
                                />
                              </Form.Field>
                            )}

                            <VSeparator />
                            <Form.Field
                              name='skuSimilarPrice'
                              label={strings.skuSimilarPrice.label}
                              onValueChange={value => moneyMask(value)}
                              validators={
                                props.totalSimilarMaterial === 0 && analysis.flowType === 'NEW_PRODUCT'
                                  ? [Validators.Required(strings.skuSimilar.alertMandatory)]
                                  : []
                              }
                            >
                              <TextField data-testid='skuSimilarPrice' type='number' noArrows={true} />
                            </Form.Field>
                            <VSeparator />
                          </Col>
                          <Col xs={6}>
                            <Form.Field name='skuObservation' label={strings.skuObservation.label}>
                              <TextAreaField
                                data-testid='skuObservation'
                                initialValue={analysis?.cancelRequestReason}
                                placeholder={strings.placeholder}
                                disableResize
                              />
                            </Form.Field>
                            <VSeparator />
                          </Col>
                        </Row>
                      )}

                      <Divisor />
                    </>
                  )
                : ''}

              <VSeparator />

              <Row middle='xs'>
                <Col xs={props?.data?.notification?.profiles.includes('CLIENT') ? 6 : 12}>
                  <Form.Field name='observation' label={strings.observationField.labelGerdau}>
                    <TextAreaField
                      data-testid='observationField'
                      initialValue={analysis?.cancelRequestReason}
                      placeholder={strings.placeholder}
                      disableResize
                    />
                  </Form.Field>
                </Col>

                {props?.data?.notification?.profiles.includes('CLIENT') && (
                  <Col xs={6}>
                    <VSeparator small />
                    <Form.Field name='observationClient' label={strings.observationField.labelClient}>
                      <TextAreaField
                        data-testid='observationClientField'
                        initialValue={analysis?.cancelRequestReason}
                        placeholder={strings.placeholder}
                        disableResize
                      />
                    </Form.Field>
                  </Col>
                )}
              </Row>
              <VSeparator small />
              <VSeparator />

              <Hbox hAlign='flex-end'>
                <Hbox.Item noGrow>
                  <Button loading={props.loading} type='submit' kind='primary'>
                    {strings.send}
                  </Button>
                </Hbox.Item>
              </Hbox>
            </Form>
            <VSeparator />
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
