/* eslint-disable complexity */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { HistoryStatus } from '@app/models/history.model';
import { FullAnalysisData } from '@app/models/quote.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { Badge } from '@atomic/atm.badge';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, DD, DL, DT, InputLabel } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { quoteBadges } from '../../quote.contants';
import { TechnicalAnalysisViewModal } from '../components/technical-analysis-view-modal.component';
import { TechnicalAnalysisStrings } from '../technical-analysis.string';

interface HistoryButtonProps {
  analysis: FullAnalysisData;
  setShowOptionsList: (status: boolean) => void;
}

export const HistoryButton: React.FC<HistoryButtonProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const strings = TechnicalAnalysisStrings[userInfo.language];

  const [modalOpenHistory, setModalOpenHistory] = React.useState(false);

  const handleOpenHistory = () => {
    setModalOpenHistory(true);
  };

  return quoteIsInternalUser ? (
    <Hbox>
      <Button kind='secondary' onClick={handleOpenHistory}>
        <Hbox hAlign='left' fullWidth>
          <Hbox.Item noGrow vAlign='center'>
            <FaIcon.History />
          </Hbox.Item>
          <Hbox.Separator />
          <Body bold color={Color.GrayXDark} noWrap>
            {strings.status.seeHistory}
          </Body>
        </Hbox>
      </Button>
      <TechnicalAnalysisViewModal
        title={strings.status.historyModalTitle}
        opened={modalOpenHistory}
        onClose={() => setModalOpenHistory(false)}
      >
        {props.analysis.history.map((status: HistoryStatus, index: number) => (
          <React.Fragment key={index + status.createdDate}>
            <InputLabel small>{status.createdDate}</InputLabel>
            <Hbox>
              <Hbox.Item noGrow vAlign='center'>
                <FaIcon.History size='sm' />
              </Hbox.Item>
              <Hbox.Separator />
              <Hbox.Item vAlign='center'>
                <VSeparator small />
                <DL horizontal>
                  <DT>{status.createdBy?.email}</DT>
                  <DD>
                    {strings.updatedStatusTo}&nbsp;
                    <Badge key={status.createdDate + index} color={quoteBadges[status.internal?.key]?.color}>
                      {status.internal?.label}
                    </Badge>
                  </DD>
                </DL>
              </Hbox.Item>
            </Hbox>
            {status.observation && (
              <Hbox>
                <Hbox.Item noGrow vAlign='center'>
                  <FaIcon.Comments size='sm' />
                </Hbox.Item>
                <Hbox.Separator />
                <Hbox.Item vAlign='center'>
                  <VSeparator small />
                  <Body>{status.observation}</Body>
                </Hbox.Item>
              </Hbox>
            )}
            {status.observationClient && (
              <Hbox>
                <Hbox.Item noGrow vAlign='center'>
                  <FaIcon.Comments size='sm' />
                </Hbox.Item>
                <Hbox.Separator />
                <Hbox.Item vAlign='center'>
                  <VSeparator small />
                  <Body>{status.observationClient}</Body>
                </Hbox.Item>
              </Hbox>
            )}
            {status.newProductEppJustify && quoteIsInternalUser && (
              <Hbox>
                <Hbox.Separator />
                <Hbox.Separator />
                <Hbox.Item vAlign='center'>
                  <VSeparator />
                  <DL horizontal>
                    <DT>
                      <Body color='purple' bold={600}>
                        {strings.header.newProductEpp} -
                      </Body>
                    </DT>
                    <DD>{status.newProductEppJustify}</DD>
                  </DL>
                </Hbox.Item>
              </Hbox>
            )}
            {status.skus && status.skus.length > 0 && (
              <Hbox>
                <Hbox.Separator />
                <Hbox.Separator />
                <Hbox.Item vAlign='center'>
                  <VSeparator small />
                  <Body>SKUS: {status.skus.join(', ')}</Body>
                </Hbox.Item>
              </Hbox>
            )}
            {status.skuSimilar && status?.skuSimilar?.code !== '' && (
              <Hbox>
                <Hbox.Separator />
                <Hbox.Separator />
                <Hbox.Item vAlign='center'>
                  <VSeparator small />
                  <Body>
                    SKU Similar: {status.skuSimilar.code}{' '}
                    {status?.skuSimilar?.price > 0 && ` - ${strings.price} R$: ${status.skuSimilar.price.toFixed(2)}`}
                  </Body>
                  <Body>{status?.skuSimilar?.observation !== '' && status.skuSimilar.observation}</Body>
                </Hbox.Item>
              </Hbox>
            )}
            <VSeparator />
            <VSeparator />
          </React.Fragment>
        ))}
      </TechnicalAnalysisViewModal>
    </Hbox>
  ) : null;
};
