import { addDays, format } from 'date-fns';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Scroll from 'react-scroll';
import { LogAnalytics } from '@app/core/analytics';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { QuoteDataSource } from '@app/data/http';
import { Chat, UnfinishedChatsResponse } from '@app/models';
import { ProfileTypesId } from '@app/models/admin.model';
import { AnalysisResume, ChatAnalysisParams } from '@app/models/analysis.model';
import { QuoteFilterData } from '@app/models/quote-filters.model';
import { AppPath } from '@app/modules/app/route-constants';
import { ErrorPlaceholder } from '@app/modules/components/error.placeholder';
import { NoDataPlaceholder } from '@app/modules/components/no-data.placeholder';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { FlashMessageTargetName } from '@app/providers';
import { useLocalStorageState } from '@app/utils/local-storage.hooks';
import { Button } from '@atomic/atm.button';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Frame } from '@atomic/atm.frame';
import { Pagination } from '@atomic/atm.pagination';
import { Hbox } from '@atomic/obj.box';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { hasIsaManagerButtonAccess, quoteIsInternalUserSelector } from '../auth/store/auth.selectores';
import { SimpleToolTip } from '../components/SimpleToolTip';
import { DateFilter } from '../components/date-filter.component';
import { useFlashMessage } from '../components/flash-message.hook';
import { OrderingFilter } from '../components/ordering-filter.component';
import { CreateOrderModal } from './components/create-order-modal/create-order-modal.component';
import { DraftListItem } from './components/draft-list-item.component';
import { HeaderListSelect } from './components/headerListSelect';
import { MenuQuotePage } from './components/menu-quote-page.component';
import { OrderAnalysisModal } from './components/order-analysis-order-modal/order-analysis-order-modal.component';
import { QuoteListItem } from './components/quote-list-item.component';
import {
  HeaderButtonWrapper,
  IconKanbanStyle,
  PaginationWrapper,
  PrevNextPaginationButton,
  TooltipsWrapper,
} from './components/quote.components.style';
import { QuoteAdvancedFilter } from './components/quoteAdvancedFilter';
import { useAnalysis } from './hooks/useAnalysis';
import { useMakeVisibleToClient } from './hooks/useMakeVisibleToClient';
import { Kanban } from './kanban/kaban.component';
import { QuoteCellShimmer } from './quote-cell.shimmer';
import { QuoteScreensAnalytics } from './quote.analytics';
import { OrderFormStrings, QuoteStrings } from './quote.string';
import { useDownloadDashboard } from './technical-analysis/hooks/useDownloadDashboard';
import { useDownloadPriceQuotation } from './technical-analysis/hooks/useDownloadPriceQuotation';
import { formatDateFilter } from './utils/formatDateFilter';

const DRAFT_TAB_TOOLTIP = 'draftTabTooltip';
const MYPENDING_TAB_TOOLTIP = 'myPendingTabTooltip';
const CLIENT_VISIBLE_TOOLTIP = 'clientVisibleTooltip';

interface ToolTipData {
  date: string;
  show: boolean;
  times: number;
}

// eslint-disable-next-line complexity
export const QuotePage: React.FC = () => {
  const limit = 10;
  let activeMenu = 0;
  let activeUrl = '';

  const dispatch = useDispatch<AppThunkDispatch>();
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const hasIsaManagerAccess = useSelector(hasIsaManagerButtonAccess);
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const [modalOpened, setModalOpened] = React.useState(false);

  const [tabIndex, setTabIndex] = React.useState(0);
  const [myPendingTooltipVisible, setMyPendingTooltipVisible] = useLocalStorageState(MYPENDING_TAB_TOOLTIP, {
    date: format(new Date(), 'yyyy-MM-dd'),
    times: 0,
    show: true,
  });
  const [clientVisibleTooltipStep1, setClientVisibleTooltipStep1] = useLocalStorageState(CLIENT_VISIBLE_TOOLTIP, {
    date: format(new Date(), 'yyyy-MM-dd'),
    show: true,
  });
  const [draftTooltipVisible, setDraftTooltipVisible] = useLocalStorageState(DRAFT_TAB_TOOLTIP, false);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [kanbanView, setKanbanView] = React.useState(false);
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [openOrderAnalysis, setOpenOrderAnalysis] = React.useState(false);

  if (location.href.includes('my-analysis')) {
    activeMenu = 1;
    activeUrl = 'my-analysis';
  } else if (location.href.includes('my-clients')) {
    activeMenu = 2;
    activeUrl = 'my-clients';
  } else if (location.href.includes('drafts')) {
    activeMenu = 3;
    activeUrl = 'drafts';
  } else if (location.href.includes('crm')) {
    activeMenu = 4;
    activeUrl = 'crm';
  } else {
    activeMenu = 0;
  }

  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const [filters, setFilters] = React.useState<QuoteFilterData>(null);
  const [filtersParams, setFiltersParams] = React.useState<QuoteFilterData>({
    sort: 'desc',
    startDate: formatDateFilter(30, null),
    endDate: formatDateFilter(0, null),
    myAnalysis: activeUrl === 'my-analysis' || false,
    myClients: activeUrl === 'my-clients' || false,
    skip: 0,
    limit: limit,
  });
  const [createOrderModalOpened, setCreateOrderModalOpened] = React.useState(false);
  const [orderSelectedItem, setOrderSelectedItem] = React.useState<AnalysisResume>();
  const [hasTokenChecked, setHasTokenChecked] = React.useState<boolean>(false);

  const verifyDateTooltip = (date: ToolTipData) => {
    const today = format(new Date(), 'yyyy-MM-dd');

    if (!date?.date) {
      setMyPendingTooltipVisible({
        date: format(new Date(), 'yyyy-MM-dd'),
        times: 0,
        show: true,
      });
    }
    if (date.date === today && myPendingTooltipVisible.times <= 3) {
      setMyPendingTooltipVisible({ ...myPendingTooltipVisible, show: true });
    }
  };

  const handleGetAnalysisError = data => {
    if (data.message.includes('401')) {
      show('alert', data.message);
    } else if (data.message.includes('400')) {
      show('alert', data.message);
    } else {
      show('alert', data.description);
    }
  };

  LogAnalytics.setCurrentScreen(QuoteScreensAnalytics.Quote);
  const unFinishedAnalysisList = useLazyRequest<{}, UnfinishedChatsResponse[]>(QuoteDataSource.getUnfinishedChatsList);

  const { mutate: handleDownloadPrice } = useDownloadPriceQuotation();

  const { isFetching: loadingDashboard, refetch: getExcelDashboard } = useDownloadDashboard({
    language: userInfo.language,
    authorizationToken: azureToken,
    accessToken: token,
  });

  useEffect(() => {
    dispatch(validateTokens()).then(() => {
      setHasTokenChecked(true);
    });
  }, [filters, filtersParams]);

  const {
    data: dataAnalysisList,
    isLoading: loadingAnalysisList,
    refetch: refetchList,
    error: errorAnalysisList,
    isFetching: refetchingAnalysisList,
  } = useAnalysis(
    {
      filtersParams,
      filters,
      quoteIsInternalUser,
      enabled: activeUrl !== 'drafts' && hasTokenChecked,
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    },
    handleGetAnalysisError,
  );

  const handleDateChange = (value: number, start?: Date, end?: Date) => {
    setFiltersParams({ ...filtersParams, startDate: formatDateFilter(value, start), skip: 0 });
    if (value === -1) {
      delete filtersParams.startDate;
      delete filtersParams.endDate;
      setFiltersParams({
        ...filtersParams,
        skip: 0,
      });
    } else if (end) {
      setFiltersParams({
        ...filtersParams,
        startDate: formatDateFilter(value, start),
        endDate: formatDateFilter(value, end),
        skip: 0,
      });
    }
    setPage(0);
  };

  const handleOrderingChange = (value: string) => {
    setFiltersParams({ ...filtersParams, sort: value });
    setPage(0);
  };

  const handleSubmit = (filterData: QuoteFilterData) => {
    setTabIndex(0);
    setPage(0);
    setModalOpened(false);
    setFiltersParams({ ...filtersParams, skip: 0, limit: limit });
    setFilters({ ...filters, ...filterData });
    refetchList();
  };

  const handleFilterRemoval = (key: string) => {
    const currentFilters = { ...filters };
    const { sort, startDate, endDate, myAnalysis, myClients, skip } = filters;

    if (key === 'ALL') {
      setFilters(null);
    } else {
      delete currentFilters[key];
      setFiltersParams({ sort, startDate, endDate, myAnalysis, myClients, limit, skip });
      setFilters({ ...currentFilters });
    }
    setPage(0);
  };

  const handleTabChange = (index: number) => {
    setSelectedItems([]);

    if (index === 1 || activeUrl === 'my-analysis') {
      setFiltersParams({ ...filtersParams, myAnalysis: true, myClients: false, sort: 'desc' });
      setPage(0);
    } else if (index === 2 || activeUrl === 'my-clients') {
      setFiltersParams({ ...filtersParams, myAnalysis: false, myClients: true, sort: 'desc' });
      setPage(0);
    } else if (index === 3 || activeUrl === 'drafts') {
      setFiltersParams({ ...filtersParams, myAnalysis: false, myClients: false, sort: 'desc' });
    } else {
      setFiltersParams({ ...filtersParams, myAnalysis: false, myClients: false, sort: 'desc' });
      setPage(0);
    }
    setKanbanView(false);
    if (activeUrl === 'drafts') {
      setTabIndex(3);
    } else {
      setTabIndex(index);
    }
  };

  const handleKanban = (status: boolean) => {
    LogAnalytics.click({ tipo: status ? 'ExibirKanban' : 'EsconderKanban' });
    setKanbanView(status);
    setFiltersParams({
      ...filtersParams,
      myAnalysis: activeUrl === 'my-analysis' && true,
      myClients: activeUrl === 'my-clients' && true,
      sort: 'desc',
    });
    setPage(0);
    setTabIndex(1);
  };

  const closeTooTipMyPending = () => {
    LogAnalytics.submit({
      tipo: 'FecharTooltipMinhasPendencias',
    });
    setMyPendingTooltipVisible({
      date: format(addDays(new Date(), 3), 'yyyy-MM-dd'),
      times: myPendingTooltipVisible.times + 1,
      show: false,
    });
    setDraftTooltipVisible(true);
  };

  const closeTooTipDraft = () => {
    LogAnalytics.submit({
      tipo: 'FecharTooltipRascunhos',
    });
    setDraftTooltipVisible(false);
  };

  const deleteUnFinishedAnalysis = useLazyRequest<ChatAnalysisParams, Chat>(QuoteDataSource.deleteUnfinishedAnalysis);

  const { mutate: makeVisibleUnvisibleAnalysis, isLoading: loadingMakeVisible } = useMakeVisibleToClient(
    handleSuccessVisible,
  );

  function handleSuccessVisible() {
    refetchList();
    setSelectedItems([]);
  }

  useEffect(() => {
    verifyDateTooltip(myPendingTooltipVisible);
    verifyTooltipClientVisible(clientVisibleTooltipStep1);

    if (activeUrl === 'drafts') {
      unFinishedAnalysisList.performRequest({
        language: userInfo.language,
        authorizationToken: azureToken,
        accessToken: token,
      });
    }
    if (dataAnalysisList) {
      setNumberOfPages(Math.ceil(checkTotalRegisters(dataAnalysisList) / limit));
    }
  }, [tabIndex, dataAnalysisList]);

  const handleAllSelectItems = (items: string[] | number[]) => {
    if (tabIndex !== 3 && activeUrl !== 'drafts' && items.length > 0) {
      items.map(item => {
        dataAnalysisList.filter(
          a =>
            a.id === item &&
            a.userCreator.profile !== 'CLIENT' &&
            a.status.internal.key !== 'INTERNAL_QUERY' &&
            a.status.internal.key !== 'INTERNAL_QUERY_SELLER' &&
            setSelectedItems(prevValue => [...prevValue, item]),
        );
      });
    } else {
      setSelectedItems(items);
    }
  };

  const handleSelectItem = (confirmationId: string, checked: boolean) => {
    if (checked) {
      setSelectedItems([...selectedItems, confirmationId]);
    } else {
      const listItems = selectedItems.filter(item => item !== confirmationId);
      setSelectedItems(listItems);
    }
  };

  const handleDeleteDraft = (confirmationId: string) => {
    deleteUnFinishedAnalysis.performRequest({ conversationId: confirmationId });

    if (!deleteUnFinishedAnalysis?.error) {
      setSelectedItems([]);
      unFinishedAnalysisList.performRequest({
        language: userInfo.language,
        authorizationToken: azureToken,
        accessToken: token,
      });
    }
  };

  const handleDeleteAllDrafts = async () => {
    for (const draft of selectedItems) {
      deleteUnFinishedAnalysis.performRequest({ conversationId: draft });
    }
    setSelectedItems([]);
    unFinishedAnalysisList.performRequest({
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    });
  };

  const checkTotalRegisters = analysisList => {
    if (tabIndex >= 3 || activeUrl === 'drafts') {
      return 0;
    } else {
      if (analysisList) {
        if (analysisList.length > 0) {
          return analysisList[0].paginationCount;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }
  };

  const handlePageChange = (value: number) => {
    Scroll.animateScroll.scrollToTop({ containerId: 'tabs' });
    setFiltersParams({ ...filtersParams, skip: value * 10 });
    setPage(value);
  };

  const handleVisilbeAllQuotation = action => {
    makeVisibleUnvisibleAnalysis({
      ids: selectedItems,
      clientVisible: action === 'make-visible' ? true : false,
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    });
  };

  const [clientVisibleTooltipStep2, setClientVisibleTooltipStep2] = React.useState(false);
  const verifyTooltipClientVisible = (date: ToolTipData) => {
    const today = format(new Date(), 'yyyy-MM-dd');

    if (!date?.date) {
      setClientVisibleTooltipStep1({
        date: format(new Date(), 'yyyy-MM-dd'),
        show: true,
      });
    }
    if (today <= date.date && page === 0) {
      setClientVisibleTooltipStep1({ ...clientVisibleTooltipStep1, show: false });
    }
  };

  const handleCloseToolTipClientVisibleStep1 = () => {
    setClientVisibleTooltipStep1({ ...clientVisibleTooltipStep1, show: false });
    setClientVisibleTooltipStep2(true);
  };

  const handleCloseToolTipClientVisibleStep2 = () => {
    setClientVisibleTooltipStep2(false);
  };

  const handleNewForm = () => {
    LogAnalytics.click({
      tipo: 'AbrirNovoForm',
    });
  };

  const handleOpenCreateOrderModal = (open: boolean, item?: AnalysisResume) => {
    setCreateOrderModalOpened(open);
    setOrderSelectedItem(item);
  };

  return (
    <>
      <Frame>
        <Grid fluid>
          <Row>
            <Col md={5} xs={12}>
              <MenuQuotePage
                id='tabs'
                tabIndex={activeMenu}
                setTabIndex={setTabIndex}
                language={userInfo.language}
                handleTabChange={handleTabChange}
                loading={refetchingAnalysisList || unFinishedAnalysisList.loading || deleteUnFinishedAnalysis.loading}
              />
              <TooltipsWrapper>
                <CustomizedTooltip
                  title={QuoteStrings[userInfo.language].tooltips.myPending.title}
                  description={QuoteStrings[userInfo.language].tooltips.myPending.description}
                  visible={myPendingTooltipVisible.show}
                  onClick={() => closeTooTipMyPending()}
                  onClose={() => closeTooTipMyPending()}
                  customHeight={40}
                  customLeftAlign={130}
                  hasOffset
                />
                <CustomizedTooltip
                  title={QuoteStrings[userInfo.language].tooltips.draft.title}
                  description={QuoteStrings[userInfo.language].tooltips.draft.description}
                  visible={draftTooltipVisible}
                  onClick={() => closeTooTipDraft()}
                  onClose={() => closeTooTipDraft()}
                  customHeight={40}
                  customLeftAlign={300}
                  hasOffset
                />
              </TooltipsWrapper>
            </Col>
            <Col md={7} xs={12}>
              <HeaderButtonWrapper>
                <Hbox hAlign='flex-end' vAlign='center'>
                  {quoteIsInternalUser && (
                    <>
                      <Hbox.Separator />
                      <Hbox.Item noGrow vAlign='center'>
                        <Button onClick={() => handleKanban(kanbanView ? false : true)}>
                          <IconKanbanStyle status={kanbanView}>
                            <FaIcon.Kanban size='1x' />
                          </IconKanbanStyle>
                          <Hbox.Separator small />
                          {kanbanView
                            ? QuoteStrings[userInfo.language].kanbanLink.list
                            : QuoteStrings[userInfo.language].kanbanLink.kanban}
                        </Button>
                      </Hbox.Item>
                    </>
                  )}
                  <Hbox.Separator />
                  {hasIsaManagerAccess && quoteIsInternalUser ? (
                    <Hbox.Item noGrow vAlign='center'>
                      <Button kind='secondary' loading={loadingDashboard} onClick={() => getExcelDashboard()}>
                        <FaIcon.Dashboard /> Dashboard
                      </Button>
                    </Hbox.Item>
                  ) : (
                    <></>
                  )}

                  <Hbox.Separator />
                  <Hbox.Item noGrow vAlign='center'>
                    <Button kind='callToAction' to={AppPath.QUOTE.FORM.BASE} onClick={() => handleNewForm()}>
                      <FaIcon.Plus />
                      {QuoteStrings[userInfo.language].newForm}
                    </Button>
                  </Hbox.Item>

                  {userInfo?.profile?.profile !== ProfileTypesId.Scm ? (
                    <>
                      <Hbox.Separator />
                      <Hbox.Item noGrow vAlign='center'>
                        <Button kind='callToAction' to={AppPath.CHAT.NEW}>
                          <FaIcon.Plus />
                          {QuoteStrings[userInfo.language].newAnalysis}
                        </Button>
                      </Hbox.Item>
                    </>
                  ) : (
                    <></>
                  )}
                </Hbox>
              </HeaderButtonWrapper>
            </Col>
          </Row>
        </Grid>
      </Frame>
      <Grid fluid>
        <Row mt>
          <Col xs={3}>
            <QuoteAdvancedFilter
              opened={modalOpened}
              onClose={() => setModalOpened(false)}
              onSubmit={handleSubmit}
              initialFilters={filters}
              handleFilterRemoval={handleFilterRemoval}
            />
          </Col>
          <Col xs={9}>
            {activeUrl !== 'drafts' && (
              <Row>
                <Col xs={6}>
                  <Hbox.Item noGrow>
                    <DateFilter onDateChange={handleDateChange} />
                  </Hbox.Item>
                  <Hbox.Separator />
                  <VSeparator />
                </Col>
                <Col xs={6}>
                  <Hbox.Item hAlign='flex-end'>
                    <OrderingFilter onSortChange={handleOrderingChange} sortAnalysis={filtersParams.sort} />
                  </Hbox.Item>
                  <Hbox.Separator />
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12}>
                <LoadingState
                  loading={
                    (loadingAnalysisList && refetchingAnalysisList) ||
                    unFinishedAnalysisList.loading ||
                    deleteUnFinishedAnalysis.loading
                  }
                  error={!!errorAnalysisList || !!unFinishedAnalysisList.error || !!deleteUnFinishedAnalysis.error}
                  data={dataAnalysisList?.length > 0 || unFinishedAnalysisList.data?.length > 0}
                >
                  <LoadingState.Shimmer>
                    <QuoteCellShimmer />
                  </LoadingState.Shimmer>

                  <LoadingState.NoData>
                    <NoDataPlaceholder
                      message={
                        activeUrl === 'my-analysis' || activeUrl === 'my-clients' || kanbanView === true
                          ? QuoteStrings[userInfo.language].placeholder.noDataMyPendings
                          : QuoteStrings[userInfo.language].placeholder.noData
                      }
                    />
                  </LoadingState.NoData>

                  <LoadingState.Error>
                    <ErrorPlaceholder onRetryClick={() => refetchList()} />
                  </LoadingState.Error>

                  {activeUrl !== 'drafts' ? (
                    kanbanView ? (
                      <Kanban dataAnalysis={dataAnalysisList} />
                    ) : (
                      dataAnalysisList?.map((item, index) => {
                        const status = quoteIsInternalUser ? item.status.internal : item.status.external;
                        const waitingClient = status.key === 'WAITING_CLIENT_REVIEW' && !quoteIsInternalUser;

                        if (
                          index === 0 &&
                          (userInfo?.profile?.profile === ProfileTypesId.Rtc ||
                            userInfo?.profile?.profile === ProfileTypesId.Seller)
                        ) {
                          return (
                            <React.Fragment key={`qi-${item.id}-${index}`}>
                              <HeaderListSelect
                                key='quotation-header'
                                type='quotation'
                                data={dataAnalysisList}
                                handleActionToAll={handleVisilbeAllQuotation}
                                handleAllSelect={handleAllSelectItems}
                                selectedItems={selectedItems}
                                loading={loadingMakeVisible}
                                toolTipVisible={clientVisibleTooltipStep2}
                                handleCloseToolTipClientVisibleStep2={handleCloseToolTipClientVisibleStep2}
                              />
                              <QuoteListItem
                                id={`q-${item.id}-${index}`}
                                item={item}
                                status={status}
                                waitingClient={waitingClient}
                                onPriceClick={() =>
                                  handleDownloadPrice({
                                    quoteId: `${item.id}`,
                                    authorizationToken: azureToken,
                                    accessToken: token,
                                  })
                                }
                                handleSelectItem={handleSelectItem}
                                selectedItems={selectedItems}
                                onCreateOrderClick={() => handleOpenCreateOrderModal(true, item)}
                              />
                              <SimpleToolTip
                                visible={clientVisibleTooltipStep1.show}
                                description={QuoteStrings[userInfo.language].tooltips.clientVisible.step1.description}
                                btnLabel={QuoteStrings[userInfo.language].tooltips.clientVisible.step1.btn}
                                align='left'
                                alignArrow='flex-start'
                                customVerticalAlign={-25}
                                customHorizontalAlign={5}
                                steps={QuoteStrings[userInfo.language].tooltips.clientVisible.step1.step}
                                onClick={handleCloseToolTipClientVisibleStep1}
                                onClose={handleCloseToolTipClientVisibleStep1}
                              />
                            </React.Fragment>
                          );
                        }
                        return (
                          <QuoteListItem
                            key={`qa-${item.id}-${index}`}
                            id={`q-${item.id}-${index}`}
                            item={item}
                            status={status}
                            waitingClient={waitingClient}
                            onPriceClick={() =>
                              handleDownloadPrice({
                                quoteId: `${item.id}`,
                                authorizationToken: azureToken,
                                accessToken: token,
                              })
                            }
                            handleSelectItem={handleSelectItem}
                            selectedItems={selectedItems}
                            onCreateOrderClick={() => handleOpenCreateOrderModal(true, item)}
                          />
                        );
                      })
                    )
                  ) : (
                    unFinishedAnalysisList?.data &&
                    unFinishedAnalysisList.data.map((item: any, index) => {
                      const status = { key: 'DRAFT', label: QuoteStrings[userInfo.language].draft };

                      if (index === 0) {
                        return (
                          <div key={`c-${item.conversation_id}-${index}`}>
                            <HeaderListSelect
                              key='draft-header'
                              type='draft'
                              data={unFinishedAnalysisList.data}
                              handleActionToAll={handleDeleteAllDrafts}
                              handleAllSelect={handleAllSelectItems}
                              selectedItems={selectedItems}
                            />
                            <DraftListItem
                              key={`a-${item.conversation_id}-${index}`}
                              id={`ee-${item.conversation_id}-${index}`}
                              status={status}
                              item={item}
                              handleDeleteDraft={handleDeleteDraft}
                              handleSelectDraft={handleSelectItem}
                              selectedDrafts={selectedItems}
                            />
                          </div>
                        );
                      }
                      return (
                        <DraftListItem
                          key={`aa-${item.conversation_id}-${index}`}
                          id={`el-${item.conversation_id}-${index}`}
                          status={status}
                          item={item}
                          handleDeleteDraft={handleDeleteDraft}
                          handleSelectDraft={handleSelectItem}
                          selectedDrafts={selectedItems}
                        />
                      );
                    })
                  )}
                </LoadingState>
              </Col>
            </Row>
            <Row mt mb>
              {dataAnalysisList?.length > 0 && !refetchingAnalysisList && activeUrl !== 'drafts' && (
                <PaginationWrapper>
                  {page > 0 && (
                    <PrevNextPaginationButton>
                      <Button size='small' onClick={() => handlePageChange(page - 1)}>
                        {QuoteStrings[userInfo.language].pagination.prev}
                      </Button>
                    </PrevNextPaginationButton>
                  )}
                  <Pagination
                    current={page + 1}
                    window={3}
                    total={numberOfPages}
                    onPageChange={value => handlePageChange(value - 1)}
                  />
                  {numberOfPages > 0 && page + 1 !== numberOfPages && (
                    <PrevNextPaginationButton>
                      <Button size='small' onClick={() => handlePageChange(page + 1)}>
                        {QuoteStrings[userInfo.language].pagination.next}
                      </Button>
                    </PrevNextPaginationButton>
                  )}
                </PaginationWrapper>
              )}
            </Row>
          </Col>
        </Row>
      </Grid>
      <CreateOrderModal
        title={OrderFormStrings[userInfo.language].title}
        opened={createOrderModalOpened}
        isAnalysisPage={false}
        onClose={() => handleOpenCreateOrderModal(false, null)}
        item={orderSelectedItem}
        setOpenOrderAnalysis={setOpenOrderAnalysis}
      />
      <OrderAnalysisModal openOrderAnalysis={openOrderAnalysis} setOpenOrderAnalysis={setOpenOrderAnalysis} />
    </>
  );
};
