import React from 'react';
import { Badge } from '@atomic/atm.badge';
import { FaIcon } from '@atomic/atm.fa-icon';
import { H2, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { FormContext } from '../form.context';
import { FieldsData } from '../utils/mapPayloadAnalysisOld.dto';
import { IconWrapper, SummaryWrapper } from './result/form-result.component.style';

export interface FormSummaryProps {
  stepIndex: number;
  stepKey: string;
  stepLabel: string;
  stepQuestion: string;
  steps?: FieldsData[];
}

export const FormSummary = (props: FormSummaryProps) => {
  const { scrollTo, calcMandatory, strings } = React.useContext(FormContext);

  const [mandatoryFieldsLeft, setMandatoryFieldsLeft] = React.useState(0);
  React.useEffect(() => {
    const time = setTimeout(() => {
      if (props.steps) {
        const fields = Object.values(props.steps);
        setMandatoryFieldsLeft(calcMandatory(fields));
      }
    }, 500);
    return () => clearTimeout(time);
  }, [props.steps]);

  return props?.steps ? (
    <SummaryWrapper bg={true} onClick={() => scrollTo(props.stepKey, null)}>
      <H3>
        {strings.newForm.step} {props.stepKey === 'CLIENTS' ? 1 : props.stepIndex + 1}
      </H3>
      <Hbox hAlign='space-between' vAlign='center'>
        <Hbox>
          <H2 bold={700}>
            {props.stepLabel}:&nbsp;<span>{props.stepQuestion}</span>
          </H2>
          {props.stepKey !== 'ADDITIONAL' ? (
            <Badge
              data-testid={`mandatory-percent-${props.stepKey}`}
              color={mandatoryFieldsLeft === 100 ? 'success' : 'orange'}
            >{`${
              calcMandatory(props.steps) === 100
                ? strings.newForm.stepStatusFull + mandatoryFieldsLeft
                : strings.newForm.stepStatusProgress + mandatoryFieldsLeft
            }%`}</Badge>
          ) : (
            <Badge color='neutral'>{strings.newForm.stepStatusOptional}</Badge>
          )}
        </Hbox>

        <IconWrapper
          data-testid={`openClose-section-${props.stepKey}`}
          rotate={0}
          onClick={() => scrollTo(props.stepKey, null)}
        >
          <FaIcon.ChevronDown color={Color.Accessory} />
        </IconWrapper>
      </Hbox>
    </SummaryWrapper>
  ) : null;
};
