import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { LAYOUT_TABS_TO_REDUCER_NAME } from '@app/core/redux/grid-layout/grid-layout.constants';
import { RootState } from '@app/core/redux/store';
import { LayoutTabs } from '@app/models/layout.model';
import { AppPath } from '@app/modules/app/route-constants';
import { RangeSlider } from '@app/modules/components/range-slider/range-slider.component';
import {
  getMinMaxLimitColumn,
  handleDebounceRangeUpdate,
} from '@app/modules/components/side-bar-filters/sidebar-filters.utils';
import {
  setRangeLimitFilter,
  setRangeValueFilter,
} from '@app/modules/components/side-bar-filters/store/sidebar-filters.store';
import { MItabIndex } from '@app/modules/order/order-tabs/MI/order-tabs-MI.constants';
import { FlexColumn } from '@atomic/obj.box';

interface IRangeFilterContentProps {
  filterKey: string;
}

export const RangeFilterContent: React.FC<IRangeFilterContentProps> = ({ filterKey }) => {
  const history = useHistory();
  const { orderTabIndex } = useSelector((state: RootState) => state.order);
  const tab = history.location.pathname === AppPath.FINANCE ? LayoutTabs.FinanceMI : MItabIndex[orderTabIndex];
  const { filters } = useSelector((state: RootState) => state.advancedFilters[tab]);
  const { minLimit, maxLimit, minVal, maxVal } = filters[filterKey];
  const [localMinVal, setLocalMinVal] = useState(0);
  const [localMaxVal, setLocalMaxVal] = useState(100);
  const data = useSelector((state: RootState) => state[LAYOUT_TABS_TO_REDUCER_NAME[tab]].data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.length) {
      const values = getMinMaxLimitColumn(data, filterKey);
      dispatch(setRangeLimitFilter({ tab, filterKey, minLimit: values.minLimit, maxLimit: values.maxLimit }));
    }
  }, [data]);

  useEffect(() => {
    setLocalMinVal(minVal);
    setLocalMaxVal(maxVal);
  }, [minLimit, maxLimit, minVal, maxVal]);

  useEffect(() => {
    handleDebounceRangeUpdate([localMinVal, localMaxVal], () =>
      dispatch(setRangeValueFilter({ tab, filterKey, minVal: localMinVal, maxVal: localMaxVal })),
    );
  }, [localMinVal, localMaxVal]);

  const disabled = !data.length || (minLimit === 0 && maxLimit === 0);

  return (
    <FlexColumn>
      <RangeSlider
        disabled={disabled}
        minLimit={minLimit}
        maxLimit={disabled ? 100 : maxLimit}
        minVal={localMinVal}
        maxVal={localMaxVal}
        setMinVal={setLocalMinVal}
        setMaxVal={setLocalMaxVal}
      />
    </FlexColumn>
  );
};
