import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AssetData } from '@app/models/quote.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { quoteBadges } from '@app/modules/quote/quote.contants';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { Badge } from '@atomic/atm.badge';
import { Cell } from '@atomic/atm.cell';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Switch } from '@atomic/atm.switch';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Col, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { useDownloadAsset } from '../hooks/useDownloadAsset';
import { ClientVisibleStyled } from './technical-analysis-assets-modal.style';
import { TechnicalAnalysisAssetsContext } from './technical-analysis-assets.context';

interface AssetCellProps {
  asset: AssetData;
  onDeleteAssets: () => void;
  onSwitchValueChanged: () => void;
}

export const AssetCell: React.FC<AssetCellProps> = props => {
  const { selectedAssets, handleSelection } = React.useContext(TechnicalAnalysisAssetsContext);
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const strings = TechnicalAnalysisStrings[userInfo.language].assets.assetCell;

  const { mutate: handleDownload, isLoading: getAssetLoading } = useDownloadAsset();

  return (
    <LoadingState loading={getAssetLoading}>
      <Cell key={props.asset.key}>
        <Row>
          <Col xs={10}>
            <Hbox>
              <Hbox.Item noGrow>
                <Hbox vAlign='center'>
                  <Separator />
                  <CheckboxField
                    data-testid='select-assets'
                    id={props.asset.key}
                    onValueChange={(_, checked) => handleSelection(props.asset, checked)}
                    checked={selectedAssets.some(asset => asset.key === props.asset.key)}
                  />
                </Hbox>
              </Hbox.Item>
              <Hbox.Separator small />
              <Hbox.Item>
                <Hbox>
                  <Hbox.Item noGrow>
                    <H3 data-testid='filename-assets'>{props.asset.filename}</H3>
                  </Hbox.Item>
                  <Hbox.Separator />
                  <Hbox.Item noGrow>
                    <Badge color={quoteBadges[props.asset.creationStatus.key]?.color}>
                      {props.asset.creationStatus.label}
                    </Badge>
                  </Hbox.Item>
                  <Hbox.Item noGrow>
                    {quoteIsInternalUser && props.asset.isClientVisible && (
                      <ClientVisibleStyled>
                        <FaIcon.Eye size='1x' color={Color.Primary} title={strings.clientVisible} />
                      </ClientVisibleStyled>
                    )}
                  </Hbox.Item>
                </Hbox>
                <VSeparator small />
                <Body>{strings.sentBy(props.asset.user.email, props.asset.date)}</Body>
              </Hbox.Item>
            </Hbox>
          </Col>
          <Col xs={2}>
            <Hbox hAlign='flex-end' vAlign='flex-start'>
              <Hbox.Item noGrow>
                <FaIcon.Custom
                  data-testid='download-assets'
                  color={Color.Primary}
                  icon='download'
                  size='1x'
                  clickable={1}
                  onClick={() =>
                    handleDownload({
                      filename: props.asset.filename,
                      url: props.asset.url,
                      type: props.asset.type,
                      language: userInfo.language,
                      authorizationToken: azureToken,
                      accessToken: token,
                    })
                  }
                />
              </Hbox.Item>

              {props.asset.permissions.isDelible && (
                <>
                  <Hbox.Separator />
                  <Hbox.Item noGrow>
                    <FaIcon.Trash
                      data-testid='delete-assets'
                      color={Color.GrayDark}
                      icon='trash'
                      size='1x'
                      clickable={1}
                      onClick={() => props.onDeleteAssets()}
                    />
                  </Hbox.Item>
                </>
              )}
            </Hbox>
          </Col>
          {props.asset.permissions.isEditable && (
            <Col xs={12}>
              <Hbox hAlign='flex-end' vAlign='flex-start'>
                <Hbox.Item noGrow>
                  <Switch
                    data-testid='make-visible-assets'
                    checked={props.asset.isClientVisible}
                    onChange={() => props.onSwitchValueChanged()}
                  >
                    {strings.clientVisible}
                  </Switch>
                </Hbox.Item>
              </Hbox>
            </Col>
          )}
        </Row>
      </Cell>
    </LoadingState>
  );
};
