import { ProfileTypesId, TabInternalName } from '@app/models/admin.model';
import { IsaInternalRoles } from '@app/models/quote.model';
import { AppPath } from '@app/modules/app/route-constants';

export const isLoggedSelector = state => !!state.auth?.token;

export const hasGsbAccessSelector = state => !!state.auth?.applications?.includes('gsb');

export const hasGabAccessSelector = state => !!state.auth?.applications?.includes('gab');

export const isEmployeeSelector = state => state.auth?.userInfo?.type === 'GERDAU';

export const hasSellerAccessSelector = state => state.auth.userInfo?.profile?.profile === ProfileTypesId.Seller;

export const hasAdminGsbSelector = state => state.auth.userInfo?.profile?.profile === ProfileTypesId.Admin;

export const hasScmAccesSelector = state => state.auth.userInfo?.profile?.profile === ProfileTypesId.Scm;

export const hasRTCAccessSelector = state => state.auth.userInfo?.profile?.profile === ProfileTypesId.Rtc;

export const hasEPPAccessSelector = state => state.auth.userInfo?.profile?.profile === ProfileTypesId.Epp;

export const hasPEDAccessSelector = state => state.auth.userInfo?.profile?.profile === ProfileTypesId.Ped;

export const hasGQAccessSelector = state => state.auth.userInfo?.profile?.profile === ProfileTypesId.Gq;

export const hasSquadAccessSelector = state => state.auth.userInfo?.profile?.profile === ProfileTypesId.Squad;

export const hasTaeAccessSelector = state => {
  if (
    state.auth.userInfo?.profile?.profile === ProfileTypesId.Epp ||
    state.auth.userInfo?.profile?.profile === ProfileTypesId.Ped ||
    state.auth.userInfo?.profile?.profile === ProfileTypesId.Gq ||
    state.auth.userInfo?.profile?.profile === ProfileTypesId.Rtc
  ) {
    return true;
  }

  return false;
};

export const hasExternalAccessSelector = state => state.auth.userInfo?.profile.profile === ProfileTypesId.Client;

export const hasInternalAccessSelector = state => {
  return (
    state.auth.userInfo?.isAdmin ||
    hasAdminGsbSelector(state) ||
    hasSellerAccessSelector(state) ||
    hasTaeAccessSelector(state) ||
    hasScmAccesSelector(state) ||
    hasSquadAccessSelector(state)
  );
};

export const hasLackOfChargeAccessSelector = state => {
  return state.auth?.userInfo?.isAdmin || hasAdminGsbSelector(state) || hasScmAccesSelector(state);
};

export const hasSchedulingCompletedAccessSelector = state => {
  return state.auth?.userInfo?.isAdmin || hasAdminGsbSelector(state) || hasScmAccesSelector(state);
};

export const quoteIsInternalUserSelector = state =>
  !!IsaInternalRoles[state.auth?.userInfo?.profile?.profile.toUpperCase()];

export const isIsaAdminSelector = state => state.auth?.userInfo?.isAdmin;

export const hasDashboardTabAccessSelector = state =>
  !!state.auth?.userInfo?.profile?.tabs.find(t => t.tag === TabInternalName.Dashboard);

export const hasQuoteTabAccessSelector = state =>
  !!state.auth?.userInfo?.profile?.tabs.find(t => t.tag === TabInternalName.Quotation);

export const hasOrderInputTabAccessSelector = state =>
  !!state.auth?.userInfo?.profile?.tabs.find(t => t.tag === TabInternalName.OrderInput);

export const hasOrdersTabAccessSelector = state =>
  !!state.auth?.userInfo?.profile?.tabs.find(t => t.tag === TabInternalName.Order);

export const hasFinanceTabAccessSelector = state =>
  !!state.auth?.userInfo?.profile?.tabs.find(t => t.tag === TabInternalName.Finance);

export const hasManifestTabAccessSelector = state =>
  !!state.auth?.userInfo?.profile?.tabs.find(t => t.tag === TabInternalName.Manifest);

export const hasCrmTabAccessSelector = state => {
  return !!state.auth?.userInfo?.profile?.tabs.find(t => t.tag === TabInternalName.Crm);
};

export const hasQuoteChatPageSelector = (): boolean => {
  return (
    location.pathname.includes(AppPath.QUOTE.BASE) ||
    location.pathname.includes(AppPath.CHAT.BASE) ||
    location.pathname.includes(AppPath.QUOTE.FORM.BASE)
  );
};

export const hasIsaManagerButtonAccess = state =>
  !!state.auth?.userInfo?.profile?.tabs.find(t => t.tag === TabInternalName.IsaManager);

export const hasAdminAccessSelector = state => state.auth.userInfo?.isAdmin;

export const hasUserManagerAccessSelector = state => {
  return hasAdminAccessSelector(state) || hasScmAccesSelector(state);
};

export const hasAdminProfileTabAccessSelector = state => state.auth?.userInfo?.isAdmin;

export const hasSurrogateAccessSelector = state => {
  return (
    state.auth?.userInfo?.isAdmin ||
    hasAdminGsbSelector(state) ||
    hasSellerAccessSelector(state) ||
    hasScmAccesSelector(state)
  );
};

export const hasPDFAccessSelector = state => {
  if (
    state.auth.userInfo?.isAdmin ||
    state.auth.userInfo?.profile?.profile === ProfileTypesId.Admin ||
    state.auth.userInfo?.profile?.profile === ProfileTypesId.Scm
  ) {
    return true;
  }

  return false;
};

export const hasPermissionToEditDocuments = state => {
  return (
    state.auth.userInfo?.isAdmin ||
    state.auth.userInfo?.profile?.profile === ProfileTypesId.Rtc ||
    state.auth.userInfo?.profile?.profile === ProfileTypesId.Gq
  );
};
