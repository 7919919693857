import React from 'react';
import { useDispatch } from 'react-redux';
import { Material } from '@app/models';
import {
  getOrderNumberError,
  hasError,
  hasInputError,
  removeMaterialError,
} from '@app/modules/order-input/order-input-validation';
import { MaterialCell } from '@app/modules/order-input/order-input.constants';
import { updateMaterial } from '@app/modules/order-input/store/order-input.store';
import { FlexColumn } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { OrderNumberErrorWrapper } from './order-number-field.style';

interface IOrderNumberProps {
  material: Material;
}

export const OrderNumberField: React.FC<IOrderNumberProps> = ({ material }) => {
  const dispatch = useDispatch();

  const handleOrderNumberChange = (updatedMaterial: Material) => {
    if (updatedMaterial.orderNumber) {
      updatedMaterial.messages = removeMaterialError(updatedMaterial.messages, MaterialCell.OrderNumber);
    } else {
      updatedMaterial.messages = [...updatedMaterial.messages, getOrderNumberError(updatedMaterial)];
    }

    dispatch(updateMaterial(updatedMaterial));
  };

  return (
    <FlexColumn>
      <FlexColumn pt={Spacing.Medium}>
        <input
          style={{ borderColor: hasError(material, MaterialCell.OrderNumber) && Color.MediumAlert }}
          defaultValue={material.orderNumber}
          onChange={e => {
            const regex = /["'@#$%&/\\;,]/g;

            if (e.currentTarget.value.match(regex)) {
              e.currentTarget.value = e.currentTarget.value.replace(regex, '');
            }
          }}
          onBlur={e => {
            const updatedMaterial = { ...material };
            updatedMaterial.orderNumber = e.target.value;
            handleOrderNumberChange(updatedMaterial);
          }}
        />
      </FlexColumn>
      <OrderNumberErrorWrapper>{hasInputError(material, MaterialCell.OrderNumber)}</OrderNumberErrorWrapper>
    </FlexColumn>
  );
};
