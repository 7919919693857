import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Container } from 'typedi';
// import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET, endpoint } from '@app/data/http';
import { endpoint, IsaHttpRequestBuilder, NewFormHttpRequestBuilder } from '@app/data/http';
import { PostFileParams } from '@app/data/http/quote-params.dto';
import { mapNLU } from '../utils/form.dto';
//import { ChatFormResponse } from '@app/models';

export async function postFile(params?: PostFileParams) {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const url = `${Container.get(NewFormHttpRequestBuilder).config.baseUrl}${endpoint.FORM}/nlu?flow=${params.flow}`;

  const form = new FormData();

  form.append('file', params.file);

  try {
    const { data, status } = await axios.post(url, form, { headers: defaultHeaders });

    if (status === 200) {
      return mapNLU(data.data);
    } else {
      return [];
    }
  } catch (error) {
    //handleUnauthorized(error);
    return [];
  }
}

export function usePostFile(onSuccess?, onError?, onSettled?) {
  return useMutation(postFile, {
    onSuccess,
    onError,
    onSettled,
    retry: false,
  });
}
