/* eslint-disable complexity */

import React from 'react';
import { Button } from '@atomic/atm.button';
import { Form, Validators } from '@atomic/obj.form';
import { LoadingState } from '@atomic/obj.loading-state';
import { FormContext } from '../../form.context';
import { FormFlowProps } from '../form-flow-fields.container';

export const TableField = (props: FormFlowProps) => {
  const { openModals, loadingCallbackChemicalComposition, strings } = React.useContext(FormContext);

  const stringsNewForm = strings.newForm.messages;

  let loading = false;
  if (props?.field.key === 'CHEMICAL_COMPOSITION') {
    loading = loadingCallbackChemicalComposition;
  }

  return (
    <LoadingState loading={loading}>
      <Form.Field
        name={props.field.key}
        validators={props?.field?.mandatory ? [Validators.Required(stringsNewForm.mandatoryAlert)] : []}
        value={props?.field?.value}
      >
        <Button onClick={() => openModals(props.field.key)}>
          {(props.field.key === 'ADDITIONAL_DIAMETER' ||
            props.field.key === 'ADDITIONAL_SIDE' ||
            props.field.key === 'ADDITIONAL') &&
          !props?.field?.value
            ? props.field.label
            : `${strings.newForm.editButtonField} ${props.field.label}`}
        </Button>
      </Form.Field>
    </LoadingState>
  );
};
