import { Invoice, InvoiceList, InvoiceME, InvoiceMEList } from '@app/models';
import { parseOrderDate } from './order-date.mapper';

export interface InvoiceListDto {
  invoice: InvoiceDto[];
}

export interface InvoiceMEListDto {
  invoice: InvoiceMEDto[];
}
export interface InvoiceDto {
  clientNumber: number;
  emitterName: string;
  emitterCNPJ: number;
  compensationDocument: number;
  statusCompensation: string;
  compensation: number;
  documentNumber: number;
  launchDate: string;
  currency: string;
  documentType: string;
  documentTypeDescription: string;
  debitCredit: string;
  amount: number;
  experyDate: string;
  invoiceKey: number;
  nfnd: string;
  rangeDaysOverdue: string;
  name: string;
  cnpj: string;
}

export interface InvoiceMEDto {
  amountBranch: number;
  clientName: string;
  clientNumber: string;
  company: string;
  compensation: number;
  compensationDocument: string;
  currency: string;
  days: number;
  documentNumber: string;
  documentType: string;
  experyDate: string;
  shipment: string;
  shipmentDate: string;
  status: string;
}

export const mapInvoiceList = (invoiceList: InvoiceListDto): InvoiceList => {
  return { invoice: mapInvoicesMI(invoiceList.invoice) };
};

export const mapInvoiceMEList = (invoiceList: InvoiceMEListDto): InvoiceMEList => {
  return { invoice: mapInvoicesME(invoiceList.invoice) };
};

export const mapInvoicesMI = (invoices: InvoiceDto[]): Invoice[] => {
  return invoices.map(item => {
    return {
      clientNumber: item.clientNumber,
      emitterName: item.emitterName,
      emitterCNPJ: item.emitterCNPJ,
      compensationDocument: item.compensationDocument,
      statusCompensation: item.statusCompensation,
      compensation: item.compensation,
      documentNumber: item.nfnd,
      launchDate: parseOrderDate(item.launchDate),
      currency: item.currency,
      documentType: item.documentTypeDescription,
      debitCredit: item.debitCredit,
      amount: item.amount,
      experyDate: parseOrderDate(item.experyDate),
      invoiceKey: item.invoiceKey,
      rangeDaysOverdue: item.rangeDaysOverdue,
      name: item.name,
      cnpj: item.cnpj,
    };
  });
};

export const mapInvoicesME = (invoices: InvoiceMEDto[]): InvoiceME[] => {
  return invoices.map(item => {
    return {
      amountBranch: item.amountBranch,
      clientName: item.clientName,
      clientNumber: item.clientNumber,
      company: item.company,
      compensation: item.compensation,
      compensationDocument: item.compensationDocument,
      currency: item.currency,
      days: item.days,
      documentNumber: item.documentNumber,
      documentType: item.documentType,
      experyDate: parseOrderDate(item.experyDate),
      shipment: item.shipment,
      shipmentDate: parseOrderDate(item.shipmentDate),
      status: item.status,
    };
  });
};
