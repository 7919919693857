import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { DD, DL, DT } from '@atomic/atm.typography';
import { DDShimmer } from '@atomic/mol.shimmer/shimmer.component';
import { Col, Row, VSeparator } from '@atomic/obj.grid';
import { IFinanceStrings, Strings } from '../finance.string';

export const FinanceCreditShimmer: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IFinanceStrings = Strings[userInfo?.language];

  return (
    <Row mb>
      <Col xs={3} md={2}>
        <DL hAlign='flex-start'>
          <DT>{strings.credit.limit}</DT>
          <DD>
            <DDShimmer />
          </DD>
        </DL>
      </Col>
      <Col xs={5} md={3}>
        <DL hAlign='flex-start'>
          <DT>{strings.credit.expirationDate}</DT>
          <DD>
            <DDShimmer />
          </DD>
        </DL>
      </Col>
      <Col xs={12} md={4}>
        <DL hAlign='flex-start'>
          <DT>{strings.credit.total}</DT>
          <DD>
            <DDShimmer />
          </DD>
        </DL>
        <VSeparator />
      </Col>
    </Row>
  );
};
