import styled from 'styled-components';
import { FlexColumn } from '@atomic/obj.box';
import { Color, FontFamily, FontSize, Spacing } from '@atomic/obj.constants';
import { Grid } from '@atomic/obj.grid';

export const SuccessMessage = styled.p`
  font-size: ${FontSize.XLarge};
  text-align: center;
  max-width: 694px;
  font-family: ${FontFamily.Primary};
  color: ${Color.GrayDark};
  font-weight: 400;
  line-height: 36px;
  margin-top: ${Spacing.Medium};
  margin-bottom: ${Spacing.Medium};
`;

export const OrderInputSuccessWrapper = styled.div`
  width: 100%;
`;

export const OrderInputSuccessGrid = styled(Grid)`
  margin-top: ${Spacing.XLarge};
`;

export const OrderInputSuccessButtonsWrapper = styled(FlexColumn)`
  width: 272px;
  padding-bottom: ${Spacing.XXLarge};
`;
