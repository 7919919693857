import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { LayoutTabs } from '@app/models/layout.model';
import {
  ORDER_PAGE_CHECKBOX_FILTER_FIELDS,
  ORDER_PAGE_DATE_FILTER_FIELDS,
  ORDER_PAGE_INPUT_FILTER_FIELDS,
  ORDER_PAGE_RANGE_FILTER_FIELDS,
} from '@app/modules/components/side-bar-filters/sidebar-filters.constants';
import { ISidebarFiltersStrings, Strings } from '@app/modules/components/side-bar-filters/sidebar-filters.strings';
import { MItabIndex } from '@app/modules/order/order-tabs/MI/order-tabs-MI.constants';
import { OrderCheckboxFilterContent } from '../filter-accordion-content/checkbox-filter-content/order-checkbox-filter-content.component';
import { DateFilterContent } from '../filter-accordion-content/date-filter-content/date-filter-content.component';
import { InputFilterContent } from '../filter-accordion-content/input-filter-content/input-filter-content.component';
import { RangeFilterContent } from '../filter-accordion-content/range-filter-content/range-filter-content.component';
import { FilterAccordion } from '../filter-accordion/filter-accordion.component';

export const OrderFilters: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: ISidebarFiltersStrings = Strings[userInfo.language];
  const { orderTabIndex } = useSelector((state: RootState) => state.order);
  const tab = MItabIndex[orderTabIndex];

  return (
    <>
      {ORDER_PAGE_DATE_FILTER_FIELDS.map(key => (
        <FilterAccordion overflowVisible key={key} title={strings.orderTabsTitles[tab][key]}>
          <DateFilterContent filterKey={key} />
        </FilterAccordion>
      ))}
      {ORDER_PAGE_INPUT_FILTER_FIELDS.map(key => (
        <FilterAccordion key={key} title={strings.orderTabsTitles[tab][key]} tooltipInfo={strings.clipboardInfo}>
          <InputFilterContent filterKey={key} />
        </FilterAccordion>
      ))}
      {ORDER_PAGE_CHECKBOX_FILTER_FIELDS.map(key => (
        <FilterAccordion key={key} title={strings.orderTabsTitles[tab][key]}>
          <OrderCheckboxFilterContent filterKey={key} />
        </FilterAccordion>
      ))}
      {tab === LayoutTabs.OpenMI &&
        ORDER_PAGE_RANGE_FILTER_FIELDS.map(key => (
          <FilterAccordion key={key} title={strings.orderTabsTitles[tab][key]} tooltipInfo={strings.rangeInfo}>
            <RangeFilterContent filterKey={key} />
          </FilterAccordion>
        ))}
    </>
  );
};
