import { debounce } from '@app/utils/debounce.utils';
import {
  BODY_HEIGHT_DISCOUNT,
  CURRENT_SELECTED_DOT_CLASS,
  ON_QTY_CHANGE_DEBOUNCE_TIME,
  ON_RANGE_CHANGE_DEBOUNCE_TIME,
  ON_VALUE_CHANGE_DEBOUNCE_TIME,
} from './sidebar-filters.constants';
import { IRangeState } from './sidebar-filters.interfaces';

export const handleDebounceInputUpdate = debounce<any>((newValue: any, fn: any) => {
  fn?.(newValue);
}, ON_VALUE_CHANGE_DEBOUNCE_TIME);

export const handleDebounceRangeUpdate = debounce<any>((newValue: any, fn: any) => {
  fn?.(newValue);
}, ON_RANGE_CHANGE_DEBOUNCE_TIME);

export const handleDebounceQtyInput = debounce<any>((newValue: any, fn: any) => {
  fn?.(newValue);
}, ON_QTY_CHANGE_DEBOUNCE_TIME);

export const getBodyHeightDiscount = countFilters => {
  if (countFilters >= 5) {
    return BODY_HEIGHT_DISCOUNT[4];
  }
  return BODY_HEIGHT_DISCOUNT[countFilters];
};

export const getMinMaxLimitColumn = (data: any[], field: string): IRangeState => {
  const value = {
    minLimit: 0,
    maxLimit: 0,
    minVal: 0,
    maxVal: 0,
  };

  if (data) {
    const columnValues: number[] = data.filter(row => !!row[field]).map(row => row[field]);

    if (columnValues.length) {
      const [min, max] = [Math.floor(Math.min(...columnValues)), Math.ceil(Math.max(...columnValues))];

      value.minLimit = min;
      value.maxLimit = max;
      value.minVal = min;
      value.maxVal = max;
    }
  }

  return value;
};

export const handleDotStyle = (isFirstDot: boolean, element: any) => {
  const classList = element.classList;

  if (classList) {
    const parentElement = element?.parentElement;

    if (parentElement) {
      const fisrtChild = parentElement.children[0];
      const secondChild = parentElement.children[1];

      if (isFirstDot) {
        let firstDotHasFrontClass = false;

        fisrtChild.classList.forEach(item => {
          if (item === CURRENT_SELECTED_DOT_CLASS) {
            firstDotHasFrontClass = true;
          }
        });

        if (!firstDotHasFrontClass) {
          fisrtChild.classList.add(CURRENT_SELECTED_DOT_CLASS);
        }

        let elementHasSelectedDotClass = false;

        secondChild.classList.forEach(classItem => {
          if (classItem === CURRENT_SELECTED_DOT_CLASS) {
            elementHasSelectedDotClass = true;
          }
        });

        if (elementHasSelectedDotClass) {
          secondChild.classList?.remove(CURRENT_SELECTED_DOT_CLASS);
        }
      } else {
        let secondDotHasFrontClass = false;

        secondChild.classList.forEach(item => {
          if (item === CURRENT_SELECTED_DOT_CLASS) {
            secondDotHasFrontClass = true;
          }
        });

        if (!secondDotHasFrontClass) {
          secondChild.classList.add(CURRENT_SELECTED_DOT_CLASS);
        }

        let elementHasSelectedDotClass = false;

        fisrtChild.classList.forEach(classItem => {
          if (classItem === CURRENT_SELECTED_DOT_CLASS) {
            elementHasSelectedDotClass = true;
          }
        });

        if (elementHasSelectedDotClass) {
          fisrtChild.classList?.remove(CURRENT_SELECTED_DOT_CLASS);
        }
      }
    }
  }
};
