import React from 'react';
import { useSelector } from 'react-redux';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Hbox } from '@atomic/obj.box';
import { FormContext } from '../../form.context';
import { FieldsData } from '../../utils/mapPayloadAnalysisOld.dto';

interface ChatOptionsProps {
  attrKey: string;
  stepKey: string;
  editable: boolean;
  type: string;
  attribute?: any;
}

export const FormResultEditOptions = (props: ChatOptionsProps) => {
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const {
    formResult,
    setFieldValue,
    setFormResult,
    openModals,
    additionalDiameters,
    setAdditionalDiameters,
    focusAttribute,
  } = React.useContext(FormContext);

  const handleEditAttribute = () => {
    focusAttribute(props.attrKey);

    if (
      props.attrKey === 'CHEMICAL_COMPOSITION' ||
      props.attrKey === 'MICRO_INCLUSION' ||
      props.attrKey === 'HARDENABILITY' ||
      props.attrKey === 'MACRO_ETCH'
    ) {
      openModals(props.attrKey);
    } else if (
      props.attrKey === 'STRETCH' ||
      props.attrKey === 'AREA_SHRINK' ||
      props.attrKey === 'YIELD_LIMIT' ||
      props.attrKey === 'LOAD_LIMIT'
    ) {
      openModals('MECHANICAL_PROPERTIES');
    } else if (
      props.attrKey === 'ADDITIONAL_DIAMETER' ||
      props.attrKey.includes('DIAMETER') ||
      props.attrKey.includes('SIDE') ||
      props.attrKey.includes('WIDTH') ||
      props.attrKey.includes('THICKNESS') ||
      props.attrKey.includes('LENGTH') ||
      props.attrKey.includes('AMOUNT')
    ) {
      openModals('ADDITIONAL_DIAMETER');
    } else {
      setFormResult(prevValue => ({
        ...prevValue,
        [props.stepKey]: {
          ...prevValue[props.stepKey],
          questions: {
            ...prevValue[props.stepKey].questions,
            fields: {
              ...prevValue[props.stepKey].questions.fields,
              [props.attrKey]: {
                ...prevValue[props.stepKey].questions.fields[props.attrKey],
                display: true,
              },
            },
          },
        },
      }));
    }
  };
  const handleDeleteAttribute = () => {
    if (props.type === 'range') {
      formResult[props.stepKey].questions.fields[props.attrKey].min = null;
      formResult[props.stepKey].questions.fields[props.attrKey].max = null;
    }
    if (props.attrKey === 'CLIENT') {
      setFieldValue('');
      setFormResult(prevValue => ({
        ...prevValue,
        [props.stepKey]: {
          ...prevValue[props.stepKey],
          questions: {
            ...prevValue[props.stepKey].questions,
            fields: {
              ...prevValue[props.stepKey].questions.fields,
              CLIENT: {
                ...prevValue[props.stepKey].questions.fields.CLIENT,
                value: null,
              },
            },
          },
        },
      }));
    } else {
      if (
        props.attrKey.includes('DIAMETER') ||
        props.attrKey.includes('SIDE') ||
        props.attrKey.includes('THICKNESS') ||
        props.attrKey.includes('WIDTH') ||
        props.attrKey.includes('LENGTH') ||
        props.attrKey.includes('AMOUNT')
      ) {
        if (props.type === 'table') {
          openModals('ADDITIONAL_DIAMETER');
        } else {
          const diameters = Object.entries(formResult.PRODUCT.questions.fields).filter(
            (field: [string, FieldsData]) => {
              const [key, _] = field;
              return (
                (key.includes('DIAMETER') ||
                  key.includes('SIDE') ||
                  key.includes('THICKNESS') ||
                  key.includes('WIDTH') ||
                  key.includes('LENGTH') ||
                  key.includes('AMOUNT')) &&
                key !== 'LENGTH_TOLERANCE' &&
                key !== 'DIAMETER_TOLERANCE' &&
                key !== 'ADDITIONAL_DIAMETER' &&
                key !== 'ADDITIONAL_SIDE'
              );
            },
          );
          diameters.forEach((attr: [string, FieldsData]) => {
            setFormResult(prevValue => ({
              ...prevValue,
              PRODUCT: {
                ...prevValue.PRODUCT,
                questions: {
                  ...prevValue.PRODUCT.questions,
                  fields: {
                    ...prevValue.PRODUCT.questions.fields,
                    [attr[1].key]: {
                      ...prevValue.PRODUCT.questions.fields[attr[1].key],
                      value: null,
                      min: null,
                      max: null,
                      display: false,
                    },
                  },
                },
              },
            }));
          });
          const diameterLeft = additionalDiameters[0];
          setAdditionalDiameters([diameterLeft]);
        }
      } else {
        setFormResult(prevValue => ({
          ...prevValue,
          [props.stepKey]: {
            ...prevValue[props.stepKey],
            questions: {
              ...prevValue[props.stepKey].questions,
              fields: {
                ...prevValue[props.stepKey].questions.fields,
                [props.attrKey]: {
                  ...prevValue[props.stepKey].questions.fields[props.attrKey],
                  value: null,
                  display:
                    props.stepKey === 'ADDITIONAL'
                      ? false
                      : prevValue[props.stepKey].questions.fields[props.attrKey].display,
                },
              },
            },
          },
        }));
        if (props.stepKey === 'ADDITIONAL') {
          delete formResult.ADDITIONAL.questions.fields[props.attrKey];
        }
      }
    }
  };

  return (
    <Hbox>
      {props.editable && (
        <Button kind='link' onClick={handleEditAttribute}>
          <FaIcon.Pen />
        </Button>
      )}
      {quoteIsInternalUser ? (
        <Button kind='link' onClick={handleDeleteAttribute}>
          <FaIcon.Trash />
        </Button>
      ) : (
        props.attrKey !== 'CLIENT' &&
        props.attrKey !== 'CLIENT_CODE' &&
        props.attrKey !== 'CORPORATE_CODE' && (
          <Button kind='link' onClick={handleDeleteAttribute}>
            <FaIcon.Trash />
          </Button>
        )
      )}
    </Hbox>
  );
};
