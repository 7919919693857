import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TagCheckboxField, TagCheckboxGroup } from '@atomic/atm.tag-checkbox';
import { Form } from '@atomic/obj.form';
import { Strings } from '../order-tabs/MI/tab-open/order-tab-open.string';
import { TagFilterWrapper } from '../order-tabs/order-tabs.component.style';

interface GridOpenRowFilterProps {
  value: string;
  onTagChanged: (value: string) => void;
}

export enum OpenMIStatusKey {
  Implanted = 'Implantado',
  Confirmed = 'Confirmado',
  OnSchedule = 'Em aprazamento',
}

export const GridOpenRowFilter: React.FC<GridOpenRowFilterProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const options = [
    {
      id: OpenMIStatusKey.Implanted,
      label: Strings[userInfo?.language].status.implanted,
      checked: true,
    },
    {
      id: OpenMIStatusKey.OnSchedule,
      label: Strings[userInfo?.language].status.schedule,
      checked: true,
    },
    {
      id: OpenMIStatusKey.Confirmed,
      label: Strings[userInfo?.language].status.confirmed,
      checked: true,
    },
  ];

  const handleDataTestId = (optionId: string) => {
    switch (optionId) {
      case OpenMIStatusKey.Implanted:
        return 'deployed-filter';
      case OpenMIStatusKey.OnSchedule:
        return 'on-schedule-filter';
      case OpenMIStatusKey.Confirmed:
        return 'confirmed-filter';
      default:
        return 'deployed-filter';
    }
  };

  return (
    <Form>
      <Form.Field
        name='openMIRadio'
        value={props.value.split(',')}
        onValueChange={value => {
          if (value.join() !== props.value) {
            props.onTagChanged(value.join());
          }
        }}
      >
        <TagCheckboxGroup>
          {options.map(option => (
            <TagFilterWrapper data-testid={handleDataTestId(option.id)} key={option.id}>
              <TagCheckboxField id={option.id}>{option.label}</TagCheckboxField>
            </TagFilterWrapper>
          ))}
        </TagCheckboxGroup>
      </Form.Field>
    </Form>
  );
};
