import { Pagination } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { setNPSUserActiveId } from '@app/modules/admin/store/admin.store';
import { getNPSUserGroup } from '@app/modules/admin/store/thunk/nps-get-group.thunk';
import { getNPSStatisticsGroup } from '@app/modules/admin/store/thunk/nps-statistics-group-get.thunk';
import { LIMIT_ITEMS_PER_PAGE } from '@app/modules/admin/utils/utils';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';

export const AdminNPSPagination: React.FC = () => {
  const { ssoAccess } = useSelector((state: RootState) => state.auth);
  const { npsUserSearch, selectedNPSGroup } = useSelector((state: RootState) => state.admin);
  const dispatch = useDispatch<AppThunkDispatch>();

  const handlePagination = page => {
    const query = {
      group: selectedNPSGroup,
      ssoAccess: ssoAccess,
      skip: 0,
      limit: LIMIT_ITEMS_PER_PAGE,
      filterType: npsUserSearch.filterOption,
      filterValue: npsUserSearch.textQuery,
    };

    if (npsUserSearch.selectedOptions?.length) {
      query.filterValue = npsUserSearch.selectedOptions.toString();
    } else if (npsUserSearch.textQuery?.length > 2) {
      query.filterValue = npsUserSearch.textQuery;
    } else {
      query.filterValue = '';
    }

    if (page === 0) {
      query.skip = 1 * LIMIT_ITEMS_PER_PAGE - LIMIT_ITEMS_PER_PAGE;
    } else {
      query.skip = Math.ceil(page) * LIMIT_ITEMS_PER_PAGE - LIMIT_ITEMS_PER_PAGE;
    }

    dispatch(setNPSUserActiveId(''));
    dispatch(getNPSStatisticsGroup({ group: selectedNPSGroup, ssoAccess }));
    dispatch(getNPSUserGroup(query));
  };

  useEffect(() => {
    if (selectedNPSGroup) {
      handlePagination(0);
    }
  }, [selectedNPSGroup]);

  if (!npsUserSearch.filteredUsers?.length) {
    return null;
  }

  return (
    <FlexRow hAlign='center' mb={Spacing.Small}>
      <Pagination
        ariaLabel='pagination'
        onChange={e => handlePagination(e)}
        select={false}
        showTotal={false}
        total={npsUserSearch.pagination.count}
        pageSizeOptions={[LIMIT_ITEMS_PER_PAGE]}
      />
    </FlexRow>
  );
};
