import { addGetAllBPsReducers } from './bps-get-all.thunk';
import { addGetBPsReducers } from './bps-get.thunk';
import { addGetAllNPSReducers } from './nps-get-all.thunk';
import { addGetNPSUserGroupReducers } from './nps-get-group.thunk';
import { addGetNPSStatisticsGroupReducers } from './nps-statistics-group-get.thunk';
import { addGetProfileReducers } from './profile-get.thunk';
import { addUpdateRoleReducers } from './profile-update.thunk';
import { addResetPasswordReducers } from './reset-password.thunk';
import { addGetTabsReducers } from './tabs-get.thunk';
import { addCreateUserReducers } from './user-create.thunk';
import { addDeleteUserReducers } from './user-delete.thunk';
import { addGetUserReducers } from './user-get.thunk';
import { addUpdateUserReducers } from './user-update.thunk';
import { addGetAllUsersReducers } from './users-get-all.thunk';
import { addGetUsersReducers } from './users-get.thunk';

export const addThunkReducers = builder => {
  addGetUserReducers(builder);
  addGetAllUsersReducers(builder);
  addCreateUserReducers(builder);
  addUpdateUserReducers(builder);
  addDeleteUserReducers(builder);
  addResetPasswordReducers(builder);
  addGetUsersReducers(builder);
  addGetBPsReducers(builder);
  addGetAllBPsReducers(builder);
  addGetTabsReducers(builder);
  addGetProfileReducers(builder);
  addUpdateRoleReducers(builder);
  addGetNPSStatisticsGroupReducers(builder);
  addGetNPSUserGroupReducers(builder);
  addGetAllNPSReducers(builder);
};
