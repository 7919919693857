import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Hbox } from '@atomic/obj.box';
import { BrandColor, Color } from '@atomic/obj.constants';
import { IOrderTabOpenStrings, Strings } from '../order-tabs/MI/tab-open/order-tab-open.string';
import { setFilter, setLackOfChargeFilter } from '../order-tabs/MI/tab-open/store/order-tab-open.store';
import { CriticalFilterButton, CriticalFilterWrapper } from '../order-tabs/order-tabs.component.style';
import { OrderPriorizationEvents } from '../order.analytics';
import { OpenMIStatusKey } from './grid-open-row-filter.component';

export const GridOpenRowLackOfChargeFilter: React.FC = () => {
  const [filterChecked, setFilterChecked] = useState(false);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const strings: IOrderTabOpenStrings = Strings[userInfo?.language];
  const dispatch = useDispatch<AppThunkDispatch>();
  const ref = useRef(null);

  const handleCheck = () => {
    LogAnalytics.click({
      tipo: OrderPriorizationEvents.OrderLackOfChargeFilterCheck,
      checked: !filterChecked,
    });
    setFilterChecked(!filterChecked);
    dispatch(setLackOfChargeFilter(!filterChecked));

    if (!filterChecked) {
      dispatch(setFilter(`${OpenMIStatusKey.Implanted},${OpenMIStatusKey.OnSchedule},${OpenMIStatusKey.Confirmed}`));
    }
  };

  const onMouseOverInfoIcon = () => {
    setTooltipVisible(true);
  };

  const onMouseOutInfoIcon = () => {
    setTooltipVisible(false);
  };

  return (
    <CriticalFilterWrapper>
      <Hbox.Separator />
      <CriticalFilterButton
        data-testid='lack-of-charge-filter'
        ref={ref}
        checked={filterChecked}
        onClick={() => handleCheck()}
        onMouseOver={onMouseOverInfoIcon}
        onMouseOut={onMouseOutInfoIcon}
      >
        <FaIcon.Custom icon='exclamation-triangle' color={filterChecked ? Color.GrayXLight : BrandColor.Blue} />
        <Hbox.Separator small />
        {Strings[userInfo?.language].lackOfChargeCheck}
      </CriticalFilterButton>
      <CustomizedTooltip
        description={strings.lackOfChargeDesc}
        visible={tooltipVisible}
        onClick={() => setTooltipVisible(true)}
        onClose={() => setTooltipVisible(false)}
        customHeight={20}
        customLeftAlign={0}
        customArrowX={90}
        hasFooter={false}
        hasHeader={false}
        hasOffset
      />
    </CriticalFilterWrapper>
  );
};
