import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { RadioField } from '@atomic/atm.radio';
import { TextField } from '@atomic/atm.text-field';
import { H1, H3 } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { rangeLimits } from '../form.contants';
import { FormContext } from '../form.context';
import { FormStrings } from '../form.string';

enum Medida {
  KGFMM = 'KGFMM',
  KSI = 'KSI',
  MPA = 'MPA',
  NMM = 'NMM',
  PSI = 'PSI',
}

export enum MechanicalField {
  ALONGAMENTO = 'ALONGAMENTO',
  REDUCAO_AREA = 'REDUCAO_AREA',
  MEDIDA_LIMITE_ESCOAMENTO = 'MEDIDA_LIMITE_ESCOAMENTO',
  MEDIDA_LIMITE_RESISTENCIA = 'MEDIDA_LIMITE_RESISTENCIA',
  LIMITE_ESCOAMENTOMIN = 'LIMITE_ESCOAMENTOMIN',
  LIMITE_ESCOAMENTOMAX = 'LIMITE_ESCOAMENTOMAX',
  LIMITE_RESISTENCIAMIN = 'LIMITE_RESISTENCIAMIN',
  LIMITE_RESISTENCIAMAX = 'LIMITE_RESISTENCIAMAX',
  TRACTION = 'TRACTION',
}

export interface MechanicalPropertiesData {
  values?: any;
  [MechanicalField.TRACTION]?: any;
  [MechanicalField.ALONGAMENTO]?: number;
  [MechanicalField.REDUCAO_AREA]?: number;
  [MechanicalField.MEDIDA_LIMITE_ESCOAMENTO]?: Medida;
  [MechanicalField.LIMITE_ESCOAMENTOMIN]?: number;
  [MechanicalField.LIMITE_ESCOAMENTOMAX]?: number;
  [MechanicalField.MEDIDA_LIMITE_RESISTENCIA]?: Medida;
  [MechanicalField.LIMITE_RESISTENCIAMIN]?: number;
  [MechanicalField.LIMITE_RESISTENCIAMAX]?: number;
}

interface MechanicalPropertiesRowProps {
  label: string;
  unit: string;
  minInitialValue?: string | number;
  maxInitialValue?: string | number;
  minName?: string;
  maxName?: string;
}
const MechanicalPropertiesRow: React.FC<MechanicalPropertiesRowProps> = props => {
  const formatStringValue = (val: string | number) => {
    if (val) {
      const newMask = val.toString().replace(rangeLimits.maxString, '-');
      return newMask;
    }
    return val;
  };

  return (
    <TR>
      <TD>
        <H3>{props.label}</H3>
      </TD>

      {props.maxName ? (
        <>
          <TD>
            <Form.Field name={props.minName} initialValue={formatStringValue(props.minInitialValue)} hideErrorCaption>
              <TextField type='text' placeholder='Min.' noArrows={true} />
            </Form.Field>
          </TD>
          <TD> - </TD>
          <TD>
            <Form.Field name={props.maxName} initialValue={formatStringValue(props.maxInitialValue)} hideErrorCaption>
              <TextField type='text' placeholder='Máx.' noArrows={true} />
            </Form.Field>
          </TD>
        </>
      ) : (
        <TD>
          <Form.Field name={props.minName} initialValue={props.minInitialValue} hideErrorCaption>
            <TextField type='text' placeholder='Min.' noArrows={true} />
          </Form.Field>
        </TD>
      )}
      <TD>
        <H3>{props.unit}</H3>
      </TD>
    </TR>
  );
};

interface ModalMechanicalPropertiesProps {
  data?: MechanicalPropertiesData;
  open: boolean;
  submitModals: (stepKey: string, key: string, values: any) => void;
}

export const ModalMechanicalProperties: React.FC<ModalMechanicalPropertiesProps> = props => {
  const { setModalToOpen } = React.useContext(FormContext);
  const [reset, setReset] = React.useState(0);
  const [opened, setOpened] = React.useState(props.open);
  const [unit, setUnit] = React.useState(null);

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = FormStrings[userInfo.language];

  const handleSubmit = (data: FormData<MechanicalPropertiesData>) => {
    if (Object.keys(data.error).length > 0) {
      return;
    }

    const newValues = [
      {
        label: 'Limite de resistência',
        key: 'LOAD_LIMIT',
        value: {
          min: !data.data.LIMITE_RESISTENCIAMIN ? rangeLimits.min : +data.data.LIMITE_RESISTENCIAMIN,
          max: !data.data.LIMITE_RESISTENCIAMAX ? rangeLimits.max : +data.data.LIMITE_RESISTENCIAMAX,
        },
        unit: data.data.MEDIDA_LIMITE_ESCOAMENTO,
      },
      {
        label: strings.modalMechanical.YIELD_LIMIT,
        key: 'YIELD_LIMIT',
        value: {
          min: !data.data.LIMITE_ESCOAMENTOMIN ? rangeLimits.min : +data.data.LIMITE_ESCOAMENTOMIN,
          max: !data.data.LIMITE_ESCOAMENTOMAX ? rangeLimits.max : +data.data.LIMITE_ESCOAMENTOMAX,
        },
        unit: data.data.MEDIDA_LIMITE_ESCOAMENTO,
      },
      {
        label: strings.modalMechanical.AREA_SHRINK,
        key: 'AREA_SHRINK',
        value: !data.data.REDUCAO_AREA ? rangeLimits.max : data.data.REDUCAO_AREA,
        unit: '%',
      },
      {
        label: strings.modalMechanical.STRETCH,
        key: 'STRETCH',
        value: !data.data.ALONGAMENTO ? rangeLimits.max : +data.data.ALONGAMENTO,
        unit: '%',
      },
    ];

    newValues.forEach(field => {
      props.submitModals('ADITIONAL', field.key, {
        key: field.key,
        label: field.label,
        question: field.label,
        type: 'table',
        permissions: { visible: true, editable: true },
        display: true,
        value: field.value,
        unit: field.unit,
      });
    });

    setModalToOpen(null);

    setOpened(false);
  };

  const handleReset = () => {
    setReset(reset + 1);
  };

  const handleClose = () => {
    setOpened(false);
    setModalToOpen(null);
  };

  const handleChangeUnit = value => {
    setUnit(value);
  };

  return (
    <Modal preventOverlayClick opened={opened} small onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Grid fluid>
          <Row mb>
            <Col xs={12}>
              <H1>{strings.modalMechanical.title}</H1>
            </Col>
          </Row>

          <Row mb>
            <Col xs={12}>
              <VSeparator />
              <Form.Field
                name={MechanicalField.MEDIDA_LIMITE_ESCOAMENTO}
                initialValue={props?.data && props.data[4]}
                validators={[Validators.Required(strings.modalMechanical.mandatory)]}
                onValueChange={e => handleChangeUnit(e)}
              >
                <Hbox>
                  <Hbox.Item noGrow>
                    <RadioField id={strings.modalMechanical.units[Medida.KGFMM]}>
                      {strings.modalMechanical.units[Medida.KGFMM]}
                    </RadioField>
                  </Hbox.Item>
                  <Hbox.Separator />
                  <Hbox.Item noGrow>
                    <RadioField id={strings.modalMechanical.units[Medida.KSI]}>
                      {strings.modalMechanical.units[Medida.KSI]}
                    </RadioField>
                  </Hbox.Item>
                  <Hbox.Separator />
                  <Hbox.Item noGrow>
                    <RadioField id={strings.modalMechanical.units[Medida.MPA]}>
                      {strings.modalMechanical.units[Medida.MPA]}
                    </RadioField>
                  </Hbox.Item>
                  <Hbox.Separator />
                  <Hbox.Item noGrow>
                    <RadioField id={strings.modalMechanical.units[Medida.NMM]}>
                      {strings.modalMechanical.units[Medida.NMM]}
                    </RadioField>
                  </Hbox.Item>
                  <Hbox.Separator />
                  <Hbox.Item noGrow>
                    <RadioField id={strings.modalMechanical.units[Medida.PSI]}>
                      {strings.modalMechanical.units[Medida.PSI]}
                    </RadioField>
                  </Hbox.Item>
                </Hbox>
              </Form.Field>
            </Col>
          </Row>
          <Row mb key={reset}>
            <Col xs={12}>
              <Table>
                <MechanicalPropertiesRow
                  label={strings.resumeAnalysis.tensile}
                  minInitialValue={props?.data && props?.data[0]?.value?.min}
                  maxInitialValue={props?.data && props?.data[0]?.value?.max}
                  minName={MechanicalField.LIMITE_RESISTENCIAMIN}
                  maxName={MechanicalField.LIMITE_RESISTENCIAMAX}
                  unit={unit}
                />
                <MechanicalPropertiesRow
                  label={strings.resumeAnalysis.yield}
                  minInitialValue={props?.data && props?.data[1]?.value?.min}
                  maxInitialValue={props?.data && props?.data[1]?.value?.max}
                  minName={MechanicalField.LIMITE_ESCOAMENTOMIN}
                  maxName={MechanicalField.LIMITE_ESCOAMENTOMAX}
                  unit={unit}
                />
                <MechanicalPropertiesRow
                  label={strings.resumeAnalysis.reduction}
                  minInitialValue={props?.data && props?.data[2]?.value}
                  minName={MechanicalField.REDUCAO_AREA}
                  unit='%'
                />
                <MechanicalPropertiesRow
                  label={strings.resumeAnalysis.elongation}
                  minInitialValue={props?.data && props?.data[3]?.value}
                  minName={MechanicalField.ALONGAMENTO}
                  unit='%'
                />
              </Table>
            </Col>
          </Row>

          <Hbox hAlign='flex-end'>
            <Hbox.Item noGrow>
              <Button kind='secondary' expanded onClick={handleReset}>
                {strings.modalMechanical.btnReset}
              </Button>
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item noGrow>
              <Button kind='primary' expanded type='submit'>
                {strings.modalMechanical.btnSubmit}
              </Button>
            </Hbox.Item>
          </Hbox>
          <VSeparator />
        </Grid>
      </Form>
    </Modal>
  );
};
