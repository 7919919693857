import { FormStrings } from './form.string';

export enum Measure {
  MM = 'mm',
  POL = 'pol',
}

export const jominyPoints = {
  [Measure.MM]: ['1.5', '3', '5', '7', '9', '11', '13', '15', '20', '25', '30', '35', '40', '45', '50'],
  [Measure.POL]: [
    '1/16',
    '2/16',
    '3/16',
    '4/16',
    '5/16',
    '6/16',
    '7/16',
    '8/16',
    '9/16',
    '10/16',
    '12/16',
    '14/16',
    '16/16',
    '18/16',
    '20/16',
    '24/16',
    '28/16',
    '32/16',
  ],
};

export const rangeLimits = {
  min: 0,
  minString: '0',
  max: 999,
  maxString: '999',
  dash: '-',
};

export const format_AUSTENITE_GRAIN_SIZE = val => {
  return val[0] === '0'
    ? val[1] + ` ${FormStrings.pt.AUSTENITE_GRAIN_SIZE_STRING}`
    : val[0] + ` ${FormStrings.pt.AUSTENITE_GRAIN_SIZE_STRING}`;
};
