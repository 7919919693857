import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { mapElementsCQList } from '@app/data/http/quote.dto';

interface ElementsCQListParams {
  name: string;
  type: string;
  plant: string;
  version: number;
  language: string;
  authorizationToken: string;
  accessToken: string;
}

export async function getElementsCQList(params: ElementsCQListParams) {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.FORM}${endpoint.RULES}/general?name=${
    params.name
  }&type=${params.type}&plant=${params.plant}&version=${params.version}`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return mapElementsCQList(data.data);
  } else {
    return [];
  }
}

export function useGetElementsCQList(params?: ElementsCQListParams, onSuccess?) {
  return useQuery(['getElementsCQList'], () => getElementsCQList(params), {
    onSuccess,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
