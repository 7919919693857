export const SLICE_NAME = 'sideBarFilters';

export const INPUT_FILTERS_INITIAL_VALUE: string[] = new Array(4).fill('');
export const RANGE_FILTERS_INITIAL_VALUE = { minLimit: 0, maxLimit: 100, minVal: 0, maxVal: 100 };
export const ON_VALUE_CHANGE_DEBOUNCE_TIME = 300;
export const ON_RANGE_CHANGE_DEBOUNCE_TIME = 500;
export const ON_QTY_CHANGE_DEBOUNCE_TIME = 1000;
export const MAX_SIZE_FILTDS = 200;
export const TOOLTIP_TOP_POSITION_REF = 105;
export const TOOLTIP_LEFT_POSITION_REF = 47;

export const ORDER_PAGE_INPUT_FILTER_FIELDS = ['ov', 'material', 'descMaterial', 'codMaterialClient'];
export const ORDER_PAGE_DATE_FILTER_FIELDS = ['dateSolClient', 'dateConfirmed'];
export const ORDER_PAGE_CHECKBOX_FILTER_FIELDS = ['plantName'];
export const ORDER_PAGE_RANGE_FILTER_FIELDS = [
  'diameter',
  'thickness',
  'width',
  'qtdConf',
  'qtyStock',
  'qtyTotalProduction',
  'qtyDelivery',
  'qtyTotalBilled',
  'qtyBillingOpen',
  'qtdSolClient',
];

export const FINANCE_PAGE_INPUT_FILTER_FIELDS = ['documentNumber', 'invoiceKey'];
export const FINANCE_PAGE_DATE_FILTER_FIELDS = ['launchDate', 'experyDate'];
export const FINANCE_PAGE_CHECKBOX_FILTER_FIELDS = ['rangeDaysOverdue', 'documentType'];

export const BODY_HEIGHT_DISCOUNT = [290, 445, 445, 485, 505];

export const OrderCheckboxFilterKeyNames = [
  { key: 'CHA', name: 'Charqueadas' },
  { key: 'MOG', name: 'Mogi das Cruzes' },
  { key: 'PIN', name: 'Pindamonhangaba' },
];

export const StatusFinanceCheckboxFilterKeyNames = [
  { key: 'CHA', name: 'Nota fiscal' },
  { key: 'MOG', name: 'Nota de débito' },
  { key: 'PIN', name: 'Renegociação de dívida' },
];

export const DocumentTypeFinanceCheckboxFilterKeyNames = [
  { key: 'CHA', name: 'Pago' },
  { key: 'MOG', name: 'A vencer' },
  { key: 'PIN', name: 'Vencida' },
];

export const CURRENT_SELECTED_DOT_CLASS = 'current-selected-dot';
