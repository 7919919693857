import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '@app/core/redux/store';
import { NPSDataSource } from '@app/data/http/nps.datasource';
import { IAdminUserList } from '@app/models/admin.model';
import { IAuthState } from '@app/modules/auth/auth.interfaces';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../admin.constants';
import { IAdminState } from '../../admin.interfaces';
import { AdminStrings } from '../../admin.string';

export const getAllNPS = createAsyncThunk(`${SLICE_NAME}/getAllNPS`, async (_, thunkAPI) => {
  const state: RootState = thunkAPI.getState() as RootState;
  const { ssoAccess }: IAuthState = state.auth;
  const { selectedNPSGroup }: IAdminState = state.admin;
  const strings = AdminStrings.pt.userView;

  return await NPSDataSource.getGroup({
    group: selectedNPSGroup,
    skip: 0,
    limit: 9999,
    filterType: '',
    filterValue: '',
    ssoAccess: ssoAccess,
  })
    .then(data => data)
    .catch(err => {
      handleUnauthorized(err);
      thunkAPI.dispatch(setToast({ show: true, text: strings.expotExcelError, variant: ToastVariant.DANGER }));
    });
});

export const addGetAllNPSReducers = builder => {
  builder.addCase(getAllNPS.pending, state => {
    state.loaloadingAllUsersding = true;
  });
  builder.addCase(getAllNPS.fulfilled, (state, action) => {
    const payload: IAdminUserList = action.payload as IAdminUserList;
    state.loadingAllUsers = false;
    state.allNPSUsers = payload.users;
  });
  builder.addCase(getAllNPS.rejected, state => {
    state.loadingAllUsers = false;
  });
};
