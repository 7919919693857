/* eslint-disable prettier/prettier */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import type { RootState } from '@app/core/redux/store';
import { OrderInputDatasource, IValidateMaterialParams } from '@app/data/http';
import { Material } from '@app/models';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { OrderInputEvents } from '../../order-input.analytics';
import { MUSASHI_CUSTOMER_ID, MUSASHI_REPLACE_CUSTOMER_ID, MaterialType, OrderInputPhaseEnum, SLICE_NAME, SearchByMaterial, BRSD_SALES_ORG, BRSI_SALES_ORG } from '../../order-input.constants';
import { IOrderInputState, SpreasheetRow } from '../../order-input.interfaces';
import { getInvalidMinimunAmount, handleCharacters } from '../../order-input.utils';

export const validateMaterials = createAsyncThunk(`${SLICE_NAME}/validateMaterials`, async (_, thunkAPI) => {
  const state: RootState = thunkAPI.getState() as RootState;
  const orderInputState = state.orderInput;
  const { selectedIssuer, spreadSheetFileRequestItems, searchBy, orderReadData } = orderInputState;
  const preCustomerIds: string = customerIdsJoinedSelector(state);
  const customerIds = preCustomerIds.replace(MUSASHI_CUSTOMER_ID, MUSASHI_REPLACE_CUSTOMER_ID);
  const readedData: SpreasheetRow[] = [];
  let readedDataQuantity = 0;

  if (orderReadData && orderReadData.length > 0) {
    orderReadData.forEach(data => {
      const dataAux = data.itens.map(aux => {
        readedDataQuantity++;

        return {
          sku: aux.sku ? aux.sku.toString() : 'notFoundGpt',
          quantity: aux.quantity.toString(),
          unitOfMeasure: aux.unitOfMeasure.toString(),
          datetCustomerRequired: aux.datetCustomerRequired.replaceAll('.', '/'),
          numberOrder: handleCharacters(aux.numberOrder.toString()),
          orderItem: handleCharacters(aux.orderItem.toString()),
        };
      });
      readedData.push(...dataAux);
    });
  }

  const payload: IValidateMaterialParams = {
    emissionCode: selectedIssuer.value,
    clientImplantationQuantity: readedData.length > 0 ? readedDataQuantity : spreadSheetFileRequestItems.length,
    typeMaterialName: searchBy === SearchByMaterial.CUSTOMER_MATERIAL_CODE ? MaterialType.CodProdClient : MaterialType.CodMatGerdau,
    requests: readedData.length > 0 ? readedData : spreadSheetFileRequestItems,
    customerIds,
  };

  return await OrderInputDatasource.validateMaterial(payload).then(data => data)
    .catch(error => {
      LogAnalytics.error({
        tipo: OrderInputEvents.ValidateMaterialError,
        responseStatus: error.response.status,
        responseData: error.response.data?.message,
        customerIds,
      });

      return thunkAPI.rejectWithValue({ status: error.response.status });
    });
});

export const addValidateMaterialsReducers = builder => {
  builder.addCase(validateMaterials.pending, state => {
    state.loadingValidateMaterials = true;
    state.query = '';
    state.materialOptions = [];
  });
  builder.addCase(validateMaterials.fulfilled, (state: IOrderInputState, action) => {
    const materials = action.payload;
    const salesOrg = state.issuerOptions?.find(option => option.customerId === state.selectedIssuer?.value)?.salesOrg;

    materials.forEach((material: Material) => {
      material.formatDescription = state.searchBy;

      if (salesOrg === BRSD_SALES_ORG || salesOrg === BRSI_SALES_ORG) {
        const invalidMinAmount = getInvalidMinimunAmount(material, salesOrg);
        material.messages = invalidMinAmount ? [...material.messages, invalidMinAmount] : material.messages;
      }
    });

    state.loadingValidateMaterials = false;
    state.phase = OrderInputPhaseEnum.DataAndAmout;
    state.selectedMaterials = materials;
  });
  builder.addCase(validateMaterials.rejected, state => {
    state.loadingValidateMaterials = false;
  });
};



