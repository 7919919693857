/* eslint-disable complexity */

import React from 'react';
import { useSelector } from 'react-redux';
import { Element } from 'react-scroll';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { FormContext } from '../form.context';
import { FieldsData } from '../utils/mapPayloadAnalysisOld.dto';
import { AutoCompleteField } from './fields/autoCompleteField';
import { CheckBoxField } from './fields/checkboxField';
import { DateField } from './fields/dateField';
import { FileField } from './fields/fileField';
import { RadioField } from './fields/radioField';
import { RangeField } from './fields/rangeField';
import { SelectDefaultField } from './fields/selectField';
import { TableField } from './fields/tableField';
import { TextDefaultField } from './fields/textField';
import { FormFlowFieldsProvider } from './form-flow-fields.context';
import { FieldWrapper, MainQuestion, QuestionWrapper, SecondaryQuestion } from './result/form-result.component.style';

export interface Options {
  label: string;
  key: string;
  value: string;
  conditional?: [
    {
      key: string;
      value: string;
      and?: [{ key: string; value: string }];
      no?: [{ key: string; value: string }];
    },
  ];
}
export interface Field {
  question: string;
  flow: string[];
  id: string;
  type: string;
  key: string;
  step: string;
  readBy: string;
  display: boolean;
  placeholder?: string;
  permissions: {
    visible: boolean;
    editable: boolean;
  };
  label?: string;
  options?: Options[];
  isConditional?: boolean;
  conditional?: [
    {
      key: string;
      value: string;
    },
  ];
  mandatory?: boolean;
  detail?: string;
  value?: any;
  min?: number;
  max?: number;
  unit?: {
    symbol: string;
    description: string;
    allowed?: [
      {
        symbol: string;
        description: string;
      },
    ];
  };
  order: number;
  callback?: string;
  loading?: boolean;
}

export interface Steps {
  key: string;
  label: string;
  question: string;
  fields: FieldsData[];
}

export interface Step {
  fields: Field;
  key: string;
  label?: string;
  question?: string;
}
export interface FormFlowProps {
  field?: FieldsData;
  stepKey?: string;
  flow?: string[];
  tabIndex?: number;
  handleValue?: (value: any, typeField: string, keyField?: string, stepKey?: string, unit?: any) => void;
  handleUpload?: (file: File[], label?: string, stepKey?: string) => void;
}

export const FormFlowFieldsContainer = (props: FormFlowProps) => {
  const { formResult } = React.useContext(FormContext);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const [displayConditional, setDisplayConditional] = React.useState(false);
  const [userType, setUserType] = React.useState<string>('GERDAU');

  React.useEffect(() => {
    setDisplayConditional(false);
    setUserType(quoteIsInternalUser ? 'GERDAU' : 'CLIENT');
    if (props?.field?.conditional?.length > 0) {
      const hasDisplayFlow = props.field.conditional.filter(item => item.key === `DISPLAY_${props.flow}`);

      if (hasDisplayFlow && hasDisplayFlow[0]?.value === 'true') {
        formResult[props.field.step].questions.fields[props.field.key].display = true;
        setDisplayConditional(true);
      } else {
        const hasValidCondition = props.field.conditional.filter(
          (op: Options) =>
            formResult[props.stepKey]?.questions?.fields[op.key]?.value?.value === op.value ||
            formResult[props.stepKey]?.questions?.fields[op.key]?.value?.value === 'AWAYS' ||
            (formResult[props.stepKey]?.questions?.fields[op.key]?.value && op.value === '*'),
        );
        if (hasValidCondition.length > 0) {
          formResult[props.field.step].questions.fields[props.field.key].display = true;
          setDisplayConditional(true);
        } else {
          formResult[props.field.step].questions.fields[props.field.key].display = false;
          setDisplayConditional(false);
        }
      }
    }
  }, [formResult]);

  return (
    (props.field?.display || displayConditional) &&
    props.field?.userType.includes(userType) && (
      // <LoadingState loading={props?.field?.key === 'STEEL_SEARCH' ? loadingCallbackSteel : false}>
      <Element name={props.field.key}>
        <QuestionWrapper
          id={`field-${props.field.key}`}
          tabindex={props.tabIndex}
          data-testid={`field${props.field.key}`}
        >
          <MainQuestion>{props.field.question}</MainQuestion>
          {props.field.detail && <SecondaryQuestion>{props.field.detail}</SecondaryQuestion>}
          <FieldWrapper></FieldWrapper>

          <FieldWrapper>
            {props.field?.type === 'autocomplete' ? (
              <AutoCompleteField field={props.field} stepKey={props.stepKey} />
            ) : props.field?.type === 'radio' ? (
              <RadioField handleValue={props.handleValue} field={props.field} stepKey={props.stepKey} />
            ) : props.field?.type === 'checkbox' ? (
              <CheckBoxField handleValue={props.handleValue} field={props.field} stepKey={props.stepKey} />
            ) : props.field?.type === 'file' ? (
              <FileField handleUpload={props.handleUpload} field={props.field} stepKey={props.stepKey} />
            ) : props.field?.type === 'date' ? (
              <DateField handleValue={props.handleValue} field={props.field} stepKey={props.stepKey} />
            ) : props.field?.type === 'table' ? (
              <TableField field={props.field} />
            ) : props.field?.type === 'select' ? (
              <SelectDefaultField handleValue={props.handleValue} field={props.field} stepKey={props.stepKey} />
            ) : props.field?.type === 'range' ? (
              <RangeField handleValue={props.handleValue} field={props.field} stepKey={props.stepKey} />
            ) : (
              <TextDefaultField handleValue={props.handleValue} field={props.field} stepKey={props.stepKey} />
            )}
          </FieldWrapper>
        </QuestionWrapper>
      </Element>
      // </LoadingState>
    )
  );
};

export const FormFlowFields = () => {
  return (
    <FormFlowFieldsProvider>
      <FormFlowFieldsContainer />
    </FormFlowFieldsProvider>
  );
};
